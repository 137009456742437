html,
body {
  //height: 100%;
  padding-bottom: 0!important;
}

body {
  font-size: rem(14);
  font-family: $theme-font-1;
  color: $theme-color-1;
  background: $body-bg;
  overflow-x: hidden;
  overflow-y: scroll!important;
}
 
img { max-width: 100%; }
a { 
  outline: none;
  color: $link-color; 
  &:hover {
    text-decoration: none; 
    color: $link-hover-color;
  }
  &:focus { 
    color: inherit;  
    text-decoration: none;
    color: $link-hover-color;
  } 
}
iframe { border: none; }
.container { position: relative; }
//li { list-style-image: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7); }

.container-fluid-down-lg {
  @include media-breakpoint-down(lg) {
    max-width: 100%;
  }
}

.container-fluid-down-md {
  @include media-breakpoint-down(md) {
    max-width: 100%;
  }
}

#barba-wrapper {
  min-height: 300px; 
  &.loading {
    background: url(/dist/media/images/preload.gif) no-repeat center 150px;
  }
}

.hvr-underline-from-left:before {
  background: $theme-color-1;
  height: 1px;
}

.barba-container > div {
  // margin-top: 240px;
  /*&.t_page_home,&.t_section_saviolab { margin-top: 0; }*/
  @include media-breakpoint-down(md){ margin-top: 215px; }
  @include media-breakpoint-down(sm){ margin-top: 130px; }
}

.theme {
  &__container { 
    background: $theme-color-4; 
    padding: 86px 0 10px;
    &--page {
      padding: 25px 0 !important;
      background: linear-gradient(to bottom, $theme-color-4 0%, $theme-white 100%);
    }
    &--light {
      padding: 25px 0 !important;
      background: $theme-white !important;
    }

    &--green {
      background: linear-gradient(to bottom, $theme-color-12 0%, $theme-white 100%);
    }

    &--green-tot {
      background: $theme-color-12;
    }

    @include media-breakpoint-down(sm) { padding: 30px 0; }
    @include media-breakpoint-down(xs) { padding: 20px 0; }
    &--virtual {
      text-align: center;
      background-color: $theme-color-15;
      padding: 50px 0;
      .container {
        max-width: 950px;
      }
      .theme__title {
        text-transform: uppercase;
        color: $theme-color-17;
        letter-spacing: 5px;
      }
      .theme__intro {
        font-size: 21px;
      }
    }

    &--virtual + &--page{
      padding: 50px 0;
    }

    &__virtual__title {
      padding: 20px;
      text-align: center;
      font-family: $theme-font-2;
      font-weight: $font-extrabold;
      font-size: 22px;
      line-height: 1.3;
      margin-bottom: 50px;
      span {
        display: block;
        text-transform: uppercase;
        color: $theme-color-17;
        font-size: 35px;
      }
    }
  }

  &__intro-title {
    font-weight: 700;
    font-size: 15px;
    margin-bottom: 40px;
    color: $theme-color-11;
    font-family: $theme-font-2;
    display: block;
  }
  &__title {
   

    font-weight: $font-extrabold;
    color: $theme-color-11;

    margin-bottom: 35px;
    font-size: 40px;
    font-family: $theme-font-2;
    /*font-weight: 300;*/
    @include media-breakpoint-down(sm) {
      font-size: 45px;
      margin-bottom: 25px;
    }
    @include media-breakpoint-down(xs) { font-size: 35px; }
  }
  &__intro {
    font-size: 16px;
    font-family: $theme-font-2;
    font-weight: $font-normal;
    margin-bottom: 30px;
    @include media-breakpoint-down(sm) { font-size: 18px; }
  }
  &__image { 
    height: 600px; 
    margin-top: 30px;
    @include media-breakpoint-down(lg) { height: 480px; }
    @include media-breakpoint-down(md) { height: 355px; }
    @include media-breakpoint-down(sm) { height: 260px; }
  }
  &__text {
    font-size: 14px;
    line-height: 21px;
    margin-top: 30px;
    padding: 0 45px 0 15px;
    @include media-breakpoint-down(md) { padding: 0 15px; }
    ul { 
      list-style-type: square; 
      li { list-style-image: none!important; }
    }
  }
  &__date {
    font-size: 14px;
    display: block;
    margin-bottom: 25px;
  }
  &__picture {
    width: 100%;
    height: 530px;
    & > div { height: 100%; }
    video { object-fit: cover; }
    @include media-breakpoint-down(xs) { 
      height: 175px; 
      &--fondatore { height: 125px; }
    }
  }
  &__link {
    @extend .product__link;  
    padding: 7px 70px!important;
    margin-bottom: 25px!important;
    @include media-breakpoint-down(sm) { padding: 7px 40px!important; }
  }
}

.newsletter-table {
  margin-top: 60px;
  thead th {
    font-size: 22px;
    font-family: $theme-font-2;
    font-weight: 300;
    border-color: $theme-color-1;
  }
  td {
    font-size: 14px;
    vertical-align: inherit;
  }
}

.list-pages__title {
  @extend .box-world__title;  
}

.embed-responsive--black video {
  background-color:$theme-black;
}

.green {
  color:$theme-color-11 !important;
}



.imageFull{
  margin-bottom: 100px;
}

//Mobile
@media (max-width: 767px) {
  .imageFull{
    margin-bottom: 0px;
  }
}