.prodotti-home {

  &__wrap {
    margin-top:35px;
  } 

  &__item{
    background-color: $theme-color-4;
    margin-bottom: 60px;
    padding: 0 !important;
    &:last-child {
      margin-bottom: 0;
    }

    .row {
      max-width: 1200px;
      margin: 0 auto;
    }

    p {
      color: $theme-color-1;
      font-family: $theme-font-2;
      line-height: 1.8;
    }
    h2 {
      font-family: $theme-font-2;
      line-height: 1;
      font-size: 35px;
      font-weight: 600;
      margin-bottom: 15px;
      color: $theme-color-30;
      @include media-breakpoint-up(lg) { 
        font-size: 50px;
      }
    }

    &__btn{
      text-align: center;
      @include media-breakpoint-up(md) { 
        text-align: left;
      }
      
      &__item {
        font-size: 15px;
        border-radius: 15px;
        background-color: $theme-white;
        display:inline-block; 
        padding: 7px 40px;
        text-align: center;
        color: $theme-color-7;
        font-weight: 600;
        min-width: 300px;
        text-transform: uppercase;
        transition: all 200ms;
    
        &:hover {
          color: $theme-white;
          background-color: $theme-color-7; 
        }
      }
    }

    .col-md-6{
      padding: 0;
      @include media-breakpoint-up(lg) { 
        padding: 0 30px;
      }
      &:nth-child(1) {
        padding: 50px;
        display: flex; 
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: center;
        @include media-breakpoint-up(md) { 
          padding: 30px 50px 30px 30px;
        }
      }

      img {
        display: block;
        width: 100%;
        height:100%;
        object-fit: cover;
      }
    }

    &:nth-child(odd){
      background-color: $theme-color-31;
      p {
        color: $theme-color-4;
      }

      .col-md-6:nth-child(1) {
        @include media-breakpoint-up(md) { 
          padding: 30px 30px 30px 50px;
        }
      }

      .col-md-6:nth-child(2) {
        @include media-breakpoint-up(md) { 
          order:-1; 
        }
      }
    }
  }
}

.imageBg{
  position: relative;
  .blockImage{
    position: relative;
    min-height: 450px;
    img{
      width: 100%;
      height: auto;
      min-height: 450px;
      object-fit: cover;
    }
  }
  .blockText{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0,0,0,0.2);
    color: #fff !important;
    h2,
    p{
      color: #fff !important;
    }
    h2{
      margin-bottom: 50px !important;
    }
    //padding: 20px;
    .row{
      max-width: 1200px;
      margin: 0 auto;
    }
    .col-md-6{
      padding: 20px;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: flex-end;
      img{
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}