.top {
  //height: 230px; 
  background: $theme-white;
  padding: 0;
  // @include media-breakpoint-down(sm){ height: 55px; }
  // transition: all 0.3s ease-out;
  z-index: 999;

  .top__row {
    background-color: $theme-color-3;
    height: 35px;
    transition: all 0.3s ease-out;
    .container {
      height: 100%;
      display: flex;
      padding: 0;
      justify-content: flex-end;
      align-items: center;
@include media-breakpoint-down(md){ padding: 0 15px; }
      > *{
        margin-left: 15px;
      }
    }
  }

  .top__loghi {
    padding-top: 45px;

    // padding-bottom: 10px;
    @include media-breakpoint-down(md) {
      padding-top: 10px;
      padding-bottom: 10px;
    }

    transition: all 0.3s ease-out;

    display: flex;
    justify-content: space-between;
    align-items: center;

    .top__logo svg {
      height: 75px;
      width: 295px;
      fill: black;
    }

    .top__groupLogo img {
      height: 75px;
    }
  }


  /*&__logo-mobile {
    position: absolute;
    top: 10px;
    left: 15px; 
    width: 38px;
    height: 40px;
  }*/


  &__nav {
    //padding-top: 25px;
    display: flex;
    width: 100%;

    .nav {
      justify-content: space-between;
      li:last-child {
        margin-top: 15px;
  
        a {
          background: #98999B;
          color: #fff;
          border-radius: 15px;
          float: right;
          border-color: #98999B;
          padding: 2px 15px;
  
          :hover {
            background-color: #fff;
            color: #98999B;
            text-decoration: none !important;
  
          }
        }
        // Remove underline effect for the last item
        .hvr-underline-from-left:before {
          height: 0;
        }
      }
      ul{
        li:last-child {
          margin-top: 0;
          a{
            background-color: inherit;
            border-radius: 0;
            color: #535353;
            float: none;
            padding: 0;
          }
        }
      }
    }

    li {
      /*flex-grow: 1;*/
      position: relative;

      // svg { fill: $theme-color-2; }
      .hvr-underline-from-left {
        overflow: inherit;
      }

      .collapsed i {
        display: none;
      }

      i {
        bottom: -25px;
        top: inherit;
        left: 25px;
        position: absolute;
      }
    }

    &__link {
      padding: 15px 0 30px;
      font-size: 16px;
      line-height: 40px;
      font-family: $theme-font-2;
      font-weight: 500;
      color: $theme-black;

      @include media-breakpoint-down(md) {
        font-size: 14px;
      }

      span {
        display: block;
        font-size: 14px;
        font-family: $theme-font-2;

        @include media-breakpoint-down(md) {
          font-size: 12px;
        }
      }

      &.hvr-underline-from-left:before {
        height: 2px;
      }

      &.active {
        position: relative;

        &:before {
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          height: 2px;
          background: $theme-color-1;
        }
      }
    }

    
  }

  &.stiky-navbar {

    // height: 200px !important;
    // transition: all 0.3s ease-in !important;
    // @include media-breakpoint-down(sm){ height: 55px!important; }
    .top__row {
      height: 0;
      overflow: hidden;
    }

    .top__loghi {
      padding-top: 10px;
    }

    .top__nav__link {
      padding: 2px 15px;
    }

    /*.top__logo-mobile {
      position: absolute;
      top: 10px;
      left: 15px;
      width: 38px;
      height: 40px;
      display: block;
    }*/
    // .c-hamburger { left: 65px!important; }
    .top__bottom {
      display: none;
    }

    .top__nav {
      ul{
        padding-bottom: 10px;
      }

      /*padding-top: 25px;*/
      @at-root li i {
        bottom: -15px;
      }

      li:first-child {
        display: block;
      }

      li:last-child {
        margin-top: 0px;
      }
    }

    /*.top__groupLogo { 
      top: 0; 
      right: 50px;
      @include media-breakpoint-down(sm){
        width: 40px;
        height: 40px;
        top: 10px; 
      }
    }*/

    .overlayMenuContainer {
      //height: calc(100vh - 55px);
      //top: 55px;
      height: 100vh;
      top: 0;
    }

    /*.top__collapse-menu { top: 65px; }*/
    .searchOverlay {
      /*height: calc(100vh - 55px);
      top: 55px;*/
      height: 100vh;
      top: 0;
    }

  }

  &__search {
    /*position: absolute;
    top: 30px;
    right: 85px;
    width: 20px;
    height: 20px;*/
    //@include media-breakpoint-down(md){ right: 40px; }
    /*@include media-breakpoint-down(sm){ 
      right: 40px; 
      top: 15px;
    }*/
  }

  &__lang {
    /*position: absolute;
    top: 20px;*/
    right: 0;

    //@include media-breakpoint-down(sm){ top: 15px; }
    &__link {
      font-size: 13px;
      font-weight: 600;
    }

    &__dropdown {
      min-width: auto;
    }
  }

  &__share {
    /*position: absolute;
    top: 45px;*/
    right: 0;
    /*width: 18px;
    height: 20px;*/
    //@include media-breakpoint-down(sm){ display: none; }
  }

  &__up {
    @include media-breakpoint-down(xs) {
      z-index: 99;
    }
  }

  &__bottom {
    padding-top: 145px;

    @include media-breakpoint-down(sm) {
      padding-top: 40px;
    }

    &__logo {
      font-size: 40px;
      font-family: $theme-font-2;
      font-weight: 400;

      strong {
        font-weight: 700;
      }

      svg {
        width: 305px;
        height: 78px;
        fill: $theme-color-2;
        margin-bottom: 20px;
        /*@include media-breakpoint-down(sm){
          width: 145px;
          height: 35px;
        }*/
      }

      @include media-breakpoint-down(md) {
        font-size: 30px;
      }

      @include media-breakpoint-down(sm) {
        font-size: 19px;
      }
    }

    .mm-form-search {
      width: 90%;
      padding: 0;
      border-bottom: 1px solid $theme-color-2;
      float: right;

      .form-group {
        margin-bottom: 0;
      }

      .form-control {
        border-radius: 0;
        border: none;
        text-align: center;
        font-size: 18px;

        &:focus {
          box-shadow: none;
        }
      }

      .btn {
        background: transparent;
        cursor: pointer;

        &:focus {
          box-shadow: none;
        }

        svg {
          fill: $theme-color-2;
        }
      }
    }
  }

  &__collapse-menu {
    background: $theme-white;
    padding: 0;
    border-top: 1px solid $theme-color-3;
    padding: 40px 0;
    position: absolute;
    /*top: 120px;*/
    left: 0;
    min-height: 170px;
    width: 100vw;
    z-index: 99;
    opacity: 0;
    transition: opacity .2s ease-out;

    &.show {
      opacity: 1;
      transition: opacity .2s ease-in;
    }

    @include media-breakpoint-down(sm) {
      display: none;
    }

    &__item {
      padding-left: 0px;

      @include media-breakpoint-down(md) {
        padding-left: 50px;
      }
    }

    &__title {
      font-size: 16px;
      font-weight: 600;
      font-family: $theme-font-2;
      display: inline-block;
      margin-bottom: 50px;
    }

    ul {
      padding-left: 0;
      list-style: none;

      li {
        i {
          position: inherit;
          top: inherit;
          bottom: inherit;
          left: inherit;
        }
      }

      &.multi-col {
        column-count: 3;
        -webkit-column-count: 3;

        li {
          position: inherit;
        }
      }

      a {
        font-size: 15px;
        line-height: 30px;

        i {
          margin-right: 20px;
          margin-left: -30px;
          opacity: 0;
          transition: opacity .4s ease-out;

          @include media-breakpoint-down(md) {
            margin-right: 10px;
            margin-left: -20px;
          }
        }

        &:hover i {
          opacity: 1;
          transition: opacity .4s ease-in;
        }
      }
    }
  }
}

/*.t_page_home {
  .top { 
    margin-top: -500px; 
    height: 380px;
    transition: none;
    @include media-breakpoint-down(sm){ 
      height: 170px; 
      margin-top: 0;
    }
    &__logo-mobile { display: none; }
    .c-hamburger { left: 15px; }
    &__nav {
      padding-top: 50px;
      li i { bottom: -25px; }
      li:first-child { display: none; }
    }
    &__groupLogo { 
      top: 30px; 
      right: 60px;
      @include media-breakpoint-down(sm){
        top: 0;
        right: 75px;
        width: 80px;
        height: 80px;
      }
      @include media-breakpoint-down(xs){
        top: 0;
        right: 70px;
        width: 40px;
        height: 40px;
      }
    }
    &__search { 
      display: none; 
      @include media-breakpoint-down(sm){ 
        display: block; 
        right: 40px;
      }
    }
    &__lang { 
      top: 45px; 
      @include media-breakpoint-down(sm){ top: 15px; }
    }
    &__share { top: 70px; }
    .popover { transform: translate3d(60px, 60px, 0px)!important; }
    &__collapse-menu {
      top: 105px;
      li:first-child { display: inline-block!important; }
    }
  }
  .overlayMenuContainer {
    height: calc(100vh - 170px);
    top: 170px;
  }
  @include media-breakpoint-down(xs){
    .searchOverlay {
      height: calc(100vh - 170px);
      top: 170px;
    }
  }
}*/

/*.show-top-bar .t_page_home .top {
  margin-top: 0;
  transition: margin .8s ease-in-out;
}*/

/*BURGER*/
.c-hamburger {
  overflow: hidden;
  position: relative;
  padding: 0;
  padding-top: 24px;
  width: 36px;
  height: 21px;
  font-size: 0;
  text-indent: -9999px;
  appearance: none;
  box-shadow: none;
  border-radius: none;
  border: none;
  cursor: pointer;
  transition: background 0.3s;
  background: transparent;
  //margin-right: 15px;
  z-index: 99999;
  display: none;

  /*top: 20px;
  left: 65px;*/
  @include media-breakpoint-down(sm) {
    display: inline-block;
  }

  &:focus {
    outline: none;
  }

  span {
    display: block;
    position: absolute;
    top: 11px;
    left: 0;
    right: 0;
    height: 3px;
    background: $theme-color-1;

    &:before,
    &:after {
      position: absolute;
      display: block;
      left: 0;
      width: 100%;
      height: 3px;
      background-color: $theme-color-1;
      content: "";
    }

    &:before {
      top: -10px;
    }

    &:after {
      bottom: -10px;
    }
  }
}

.c-hamburger--htx span {
  transition: background 0s 0.3s;

  &:before,
  &:after {
    transition-duration: 0.3s, 0.3s;
    transition-delay: 0.3s, 0s;
  }

  &:before {
    transition-property: top, transform;
  }

  &:after {
    transition-property: bottom, transform;
  }
}

/* active state, i.e. menu open */
.c-hamburger--htx.is-active {
  background-color: transparent;

  span {
    background: none;

    &:before {
      top: 0px;
      transform: rotate(45deg);
    }

    &:after {
      bottom: 0px;
      transform: rotate(-45deg);
    }

    &:before,
    &:after {
      transition-delay: 0s, 0.3s;
    }
  }
}

/*FINE BURGER*/

// OVERLAY MENU
.overlayMenuContainer {
  position: absolute;
  //top: 55px;
  left: 0;
  width: 100%;
  //height: calc(100vh - 55px);
  height: 100vh;
  background-color: $theme-white;
  overflow: hidden;
  display: none;
  z-index: 99;

  &__container {
    height: 100%;
  }

  ul {
    padding: 15px 0;
    margin: 0;
    list-style: none;
    height: 100%;
  }

  li {
    display: block;
    padding: 0;

    &.intro {
      transition-property: transform, opacity;
      transition-duration: 500ms;
      transition-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
      transform: scale(1);
      opacity: 1;
    }

    &.introOut {
      transition-property: transform, opacity;
      transition-duration: 500ms;
      transition-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
      transform: scale(2);
      opacity: 0;
    }

    a {
      font-size: 15px;
      font-weight: 500;
      transform: perspective(1px) translateZ(0);
      transition-duration: 0.3s;

      span {
        font-size: 13px;
        display: block;
      }

      &.hvr-underline-from-left:before {
        background: $theme-color-1;
        height: 2px;
      }
    }
  }
}

// OVERLAY MENU
.searchOverlay {
  position: absolute;
  //top: 90px;
  left: 0;
  width: 100%;
  //height: calc(100vh - 90px);
  height: 100vh;
  background-color: $theme-white;
  overflow: hidden;
  display: none;
  z-index: 99;

  /* @include media-breakpoint-down(sm){
    height: calc(100vh - 55px);
    top: 55px;
  }*/
  &__container {
    top: 50%;
    transform: translateY(-50%);
  }

  .mm-form-search {
    width: 50%;
    padding: 0;
    border-bottom: 1px solid $theme-color-2;
    margin: 0 auto !important;

    @include media-breakpoint-down(xs) {
      width: 80%;
    }

    .form-group {
      margin-bottom: 0;
    }

    .form-control {
      border-radius: 0;
      border: none;
      text-align: center;
      font-size: 18px;

      &:focus {
        box-shadow: none;
      }
    }

    .btn {
      background: transparent;
      cursor: pointer;

      &:focus {
        box-shadow: none;
      }

      svg {
        fill: $theme-color-2;
      }
    }
  }
}