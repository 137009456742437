.rivenditori {
  &__image {
    height: 795px;
    margin-bottom: 40px;
    @include media-breakpoint-down(lg) { height: 675px; }
    @include media-breakpoint-down(md) { height: 615px; }
    @include media-breakpoint-down(sm) { height: 405px; }
    @include media-breakpoint-down(xs) { 
      height: 270px; 
      background-size: contain;
    }
  }
  &__text {
    strong { font-size: 14px; }
    h2 {
      font-size: 50px;
      margin-top: 35px;
      margin-bottom: 0;
      font-family: $theme-font-2;
      font-weight: 300;
      strong { 
        font-weight: 500; 
        font-size: 50px;
      }
      @include media-breakpoint-down(lg) { 
        font-size: 53px; 
        strong { font-size: 53px; }
      }
      @include media-breakpoint-down(xs) { 
        font-size: 40px; 
        line-height: 35px;
        margin-top: 10px;
        strong { font-size: 40px; }
      }
    }
    h3 {
      display: block;
      font-size: 26px;
      font-weight: 300;
      margin-bottom: 50px;
      @include media-breakpoint-down(md) { font-size: 24px; }
      @include media-breakpoint-down(sm) { margin-bottom: 30px; }
      @include media-breakpoint-down(xs) { 
        font-size: 16px; 
      }
    }
    p { font-size: 14px; }
  }
  &__link { 
    @extend .product__link;  
    margin-top: 25px!important;
    @include media-breakpoint-down(sm) { margin-top: 0!important; }
  }
}