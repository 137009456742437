.overlay {
  position: relative;
  width: 100%;
  height: 100vh;
  background: $theme-white;
  z-index: 40000;
  @include media-breakpoint-down(sm) { display: none; }
  svg {
    position: absolute;
    width: 625px;
    height: 158px;
    fill: $theme-color-2;
    bottom: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(50%);
    display: none;
  }  
  &__logo {
    position: absolute;
    width: 625px;
    height: 158px;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    background-image: url('/images/logo2Overlay.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: none;
  }
}