.form-contact {
  background: linear-gradient(to bottom, $theme-color-4 0%, $theme-white 100%);
  padding: 60px 0 0;
  &--resarea { 
    background: transparent; 
    @include media-breakpoint-down(xs) { padding-top: 40px!important; }
  }
  &__label { @extend .product__variants__label }
  &__title { @extend .product__variants__title }
  .form-group { 
    margin-bottom: 40px;
    label {
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 700;
      border-bottom: 1px solid $theme-black;
      display: block;
      padding-bottom: 7px;
      &.custom-checkbox { 
        padding-bottom: 3px; 
        &.is-invalid { border-bottom: 1px solid #dc3545; }
      }
      &.custom-control {
        border-bottom: none;
        padding-left: 0;
        color: $theme-color-2;
        text-transform: none;
        font-weight: 400;
        font-size: 14px;
      }
      &.is-invalid { 
        border-bottom: 1px solid #dc3545; 
        color: #dc3545;
      }
    }
    &--message {
      text-align: center;
      label { display: inline-block; }
      textarea { height: 75px; }
    }
  }
  .form-control {
    border: none;
    border-bottom: 1px solid $theme-black;
    border-radius: 0;
    background: transparent;
    height: 26px;
    padding: 0 0.75rem;
    &:focus { box-shadow: none; }
    &.is-invalid { border-bottom: 1px solid #dc3545; }
  }
  .custom-control-description { 
    padding-left: 5px; 
    &.is-invalid { color: #dc3545; }
  }
  .btn-primary {
    text-transform: uppercase;
    font-size: 12px;
    font-family: $theme-font-2;
    background: $theme-color-1;
    color: $theme-white;
    overflow: hidden;
    border-radius: 15px;
    float: right;
    border-color: $theme-color-1;
    padding: 7px 20px;
    letter-spacing: 2px;
    i { margin-left: 15px; }
    &.hvr-sweep-to-right:before {
      background: $theme-white;
      border-color: $theme-white;
    }
    &:hover { color: $theme-color-1; }
    &:focus { box-shadow: none; }
  }
  @include media-breakpoint-down(xs) { 
    padding: 15px 0;
    form { padding: 0; }
  }
}