.catalog-intro {
  background: $theme-color-4;
  padding: 60px 0;
  @include media-breakpoint-down(sm) { padding: 0 0 40px; }
  &--elementum-parent{
    background: linear-gradient(to bottom, $theme-color-12 0%, $theme-white 100%);
    .catalog-intro__title, h4, h6{
      color: $theme-color-11 !important;
    }
  }
  &--elementum-parent + .other-categories--elementum{
    background: $theme-white;
    //background: linear-gradient(to bottom, $theme-color-13 0%, $theme-white 100%);
  }
  &--elementum {
    background-color: $theme-white;
    .catalog-intro__title {
      display: flex;
      align-items: center;
      font-size:40px;
      font-weight: $font-extrabold;
      color: $theme-color-11;
      svg { 
        margin-right: 10px;
        width: 40px;
        height: 40px;
        fill: $theme-color-11;
        @include media-breakpoint-down(md) { margin-right: 0; }
      }
    }
  }
  &__title {
    margin-bottom: 35px;
    font-size: 50px;
    font-family: $theme-font-2;
    font-weight: 800;
    svg { fill: $theme-color-2; }
    @include media-breakpoint-down(sm) {
      font-size: 45px;
      margin-bottom: 25px;
    }
    @include media-breakpoint-down(xs) { font-size: 35px; }
  }

  &__small-title, h2{
    font-size: 25px;
    font-weight: 600;
    color:$theme-color-11;
    margin-bottom: 35px;
  }

  &__text {
    font-size: 16px;
    font-family: $theme-font-2;
    font-weight: $font-normal;
  }
}

.catalog-intro--elementum + .other-categories--elementum{
  background: $theme-white;
}

.other-categories {
  background: $theme-white;
  //background: linear-gradient(to bottom, $theme-color-4 0%, $theme-white 100%);
  @include media-breakpoint-down(xs) { padding: 0; } 
}

.evidence-cat {
  margin-bottom: 60px;
  @include media-breakpoint-down(xs) { margin-bottom: 35px; }
  &--kit {
    .evidence-cat__title--dark { 
      top: 395px; 
      @include media-breakpoint-down(lg) { top: 305px; }
      @include media-breakpoint-down(md) { top: 200px; }
    }
  }
  &:hover {
    .evidence-cat__image {
      &__overlay { 
        opacity: .3; 
        background-color: $theme-black;
      }
    }
    .evidence-cat__title {
      right: 105px;
      transition: all .4s ease-out;
      @include media-breakpoint-down(lg) { right: 75px; }
      @include media-breakpoint-down(md) { right: 35px; }
      @include media-breakpoint-down(sm) { right: inherit; }
      &--dark { 
        right: 160px; 
        @include media-breakpoint-down(lg) { right: 130px; }
        @include media-breakpoint-down(md) { right: 90px; }
      }
    }
  }
  &__image {
    width: 100%;
    height: 475px;
    padding-right: 40px;
    margin-bottom: 0;
    @include media-breakpoint-down(lg) { height: 395px; }
    @include media-breakpoint-down(md) { height: 290px; }
    @include media-breakpoint-down(sm) { 
      height: 210px; 
      padding-right: 0;
      margin-bottom: 10px;
    }
    div {
      width: 100%;
      height: 100%;
      position: relative;
      overflow: hidden;
      background-color: rgba($theme-color-2, .2);
      @include media-breakpoint-down(sm) { overflow: inherit; }
    }
    &__overlay { 
      position: absolute;
      background-color: transparent;
      width: 100%;
      height: 100%;
      top: 0;
      right: 0;
    }
  }
  &__title {
    color: $theme-white;
    font-size: 36px;
    font-family: $theme-font-2;
    font-weight: 500;
    position: absolute;
    right: -40px;
    top: 395px;
    transition: all .4s ease-in;
    z-index: 99;
    svg {
      fill: $theme-white;
      margin: 0 15px;
    }
    a { color: $theme-white; }
    &--dark {
      color: $theme-color-1;
      right: 15px;
      z-index: 1;
      top: 390px;
    }
    @include media-breakpoint-down(lg) { 
      top: 305px; 
      &--dark {top: 300px; }
    }
    @include media-breakpoint-down(md) { 
      top: 200px; 
      font-size: 28px;
      svg { margin: 0 5px; }
      &--dark { 
        line-height: 2.1; 
        top: 188px;
      }
    }
    @include media-breakpoint-down(sm) { 
      font-size: 18px;
      color: $theme-color-1;
      position: relative;
      top: inherit;
      right: inherit;
      a { 
        color: $theme-color-1; 
        display: block;
        margin-top: 15px;
      }
      svg {
        fill: $theme-color-1;
      }
      &--dark { display: none;  }
    }
    @include media-breakpoint-down(xs) { 
      svg {
        width: 30px;
        height: 30px;
      }
    }
  }
}

.primati{
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
  margin-bottom: 50px;
  @include media-breakpoint-up(md) { 
    margin-bottom: 125px;
  }
  &__block {
    box-sizing: border-box;
    width: calc(100%% - 20px);
    margin: 10px;
    padding: 50px 30px;
    box-shadow: 0 0 5px rgba($theme-black, 0.15);
    background-color: $theme-color-29;
    display: flex;
    flex-direction: column;

    @include media-breakpoint-up(md) { 
      width: calc(33.333332% - 20px);
    }
  }

  &__img {
    //height: 75px;
    text-align:center;
    margin-bottom:25px;
    img { 
      height: 75px;
    }
  }

  &__title {
    text-align: center;
    //font-size: 16px;
    font-weight: 700;
    margin-bottom: 15px;
  }

  &__text {
    text-align: center;
    line-height: 1.3em;
  }
}