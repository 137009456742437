// Lista sottocategorie di Prodotti
.main-categories {
  background: $theme-color-4;
  @include media-breakpoint-down(xs) { padding: 0; }
  &__item {
    margin-bottom: 80px;
    @include media-breakpoint-down(xs) { margin-bottom: 45px; }
    &:hover {
      .main-categories__image {
        &__overlay { 
          opacity: .3; 
          background-color: $theme-black;
        }
      }
      .main-categories__title {
        right: 80px;
        transition: all .4s ease-out;
        @include media-breakpoint-down(lg) { right: 50px; }
        @include media-breakpoint-down(md) { right: 10px; }
        @include media-breakpoint-down(sm) { right: inherit; }
        &--dark { 
          right: 125px; 
          @include media-breakpoint-down(lg) { right: 95px; }
          @include media-breakpoint-down(md) { right: 55px; }
        }
      }
    }
  }
  &__image {
    width: 100%;
    height: 465px;
    padding-right: 30px;
    margin-bottom: 60px;
    @include media-breakpoint-down(lg) { height: 385px; }
    @include media-breakpoint-down(md) { height: 275px; }
    @include media-breakpoint-down(sm) {
      height: 210px;
      padding-right: 0;
      margin-bottom: 30px;
    } 
    div {
      width: 100%;
      height: 100%;
      position: relative;
      overflow: hidden;
      @include media-breakpoint-down(sm) { overflow: inherit; }
    }
    &__overlay { 
      position: absolute;
      background-color: transparent;
      width: 100%;
      height: 100%;
      top: 0;
      right: 0;
    }
  }
  &__title {
    color: $theme-white;
    font-size: 35px;
    font-family: $theme-font-2;
    font-weight: 500;
    position: absolute;
    right: -25px;
    top: 395px;
    transition: all .4s ease-in;
    z-index: 2;
    &--dark {
      color: $theme-color-1;
      right: 20px;
      z-index: 1;
      line-height: 1.2;
    }
    @include media-breakpoint-down(lg) { top: 305px; }
    @include media-breakpoint-down(md) { 
      top: 225px; 
      font-size: 28px;
    }
    @include media-breakpoint-down(sm) { 
      font-size: 18px;
      color: $theme-color-1;
      position: relative;
      top: inherit;
      right: inherit;
      &--dark { display: none;  }
    }
  }
  &__text {
    padding-left: 95px;
    font-size: 14px;
    line-height: 20px;
    @include media-breakpoint-down(md) { padding-left: 0; }
    @include media-breakpoint-down(sm) { display: none; }
  }
  &__link {
    font-size: 14px;
    letter-spacing: 2px;
    display: inline-block;
    color: $theme-color-2;
    i { margin-left: 20px; }
  }
}