.list-kit {
  &__item {
    @extend .list-collection__item;
    &:hover {
      .list-kit__title {
        right: 50px;
        @include media-breakpoint-down(lg) { right: 5px; }
        @include media-breakpoint-down(md) { right: 5px; }
        @include media-breakpoint-down(sm) { right: inherit; }
        &--dark { 
          right: 105px; 
          @include media-breakpoint-down(lg) { right: 60px; }
          @include media-breakpoint-down(md) { right: 60px; }
        }
      }
      .list-kit__image {
        &__overlay { 
          opacity: .3; 
          background-color: $theme-black;
        }
      }
    }

    &--config{
      text-align: center;
      circle {
        fill: $theme-color-14 !important;
      }

      path {
        fill: $theme-color-10 !important;
      }
      svg {
        max-width: 180px;
        margin-bottom: 40px;
      }

      .list-kit__title--static a{
        color:$theme-color-24;
        font-size: 20px;
        font-weight: $font-bold;
        font-family: $theme-font-2;
      }

    }
  }
  &__image {
    @extend .list-collection__image;
  }

  &__icon {
    height: 20px;
    @media screen and (min-width:560px) {
      height: 25px;
    }
    @media screen and (min-width:992px) {
      height: 35px;
    }
    svg {
      height: 100%;
      width: auto !important;
    }
  }
  &__title {
    @extend .list-collection__title;
    &--dark {
      color: $theme-color-1;
      right: 20px;
      z-index: 1;
      line-height: 1.6;
      top: 400px;
    }
    &--static {
      font-family: $theme-font-2;
      font-size: 25px;
      font-weight: 600;
    }
    svg {
      fill: $theme-white;
      margin-right: 15px;
      @include media-breakpoint-down(lg) { margin-right: 10px; }
    }
    @include media-breakpoint-down(lg) { 
      &--dark { top: 310px; }
    }
    @include media-breakpoint-down(md) { 
      top: 185px; 
      font-size: 22px;
      svg { 
        margin-right: 5px; 
        width: 45px;
        height: 45px;
      }
      &--dark { line-height: 1.9; }
    }
    @include media-breakpoint-down(sm) { 
      font-size: 18px;
      color: $theme-color-1;
      position: relative;
      top: inherit;
      right: inherit;
      a { 
        color: $theme-color-1; 
        display: block;
        margin-top: 15px;
      }
      svg {
        fill: $theme-color-1;
      }
      &--dark { display: none;  }
    }
  }
}