$theme-white: #fff;
$theme-black: #000;

$theme-color-1: #535353;
$theme-color-2: #98999b;
$theme-color-3: #f1f2f2;
$theme-color-4: #f6f6f6;
$theme-color-5: #E3E2E7;
$theme-color-6: #e8f6fb;
$theme-color-7: #231f20;
$theme-color-8: #584932;
$theme-color-9: #ABA49A;
$theme-color-10:#6F6251;
$theme-color-11:#174E3B;

// gradient per pag Elementum
$theme-color-12:#e4e8df;
$theme-color-13:#f8f9f6;


$theme-color-14:#D8D2CA;

// Saviolab
$theme-color-15:#e4ebf1;
$theme-color-16:#B68150;
$theme-color-17:#D0C781;

$theme-color-18:#8CB7C9; 
$theme-color-19:#f5f4e2;
$theme-color-20:#9BAE88;
$theme-color-21:#F5EAE2;


$theme-color-22:#36563D;
$theme-color-23:#E6EBE2;
$theme-color-24:#726658;
$theme-color-25:#F8F5EB;

$theme-color-26:#8C3C47;

$theme-color-27:#B78250;
$theme-color-28:#c6858d;

$theme-color-29:#E6EBE3;

// Nuova home
$theme-color-30:#9D9D9D;
$theme-color-31:#3C3C3B;

 
$text-color: $theme-color-1; 
$link-color: $theme-color-1;
$link-hover-color: $theme-color-1;
$body-bg: $theme-white;

$slick-loader-path : "/images/"; 

// Fonts
$theme-font-base:14px;
$theme-font-1: 'Open Sans', sans-serif;
$theme-font-2: 'Montserrat', sans-serif;
$slick-font-path: "/dist/fonts/";


// Easings
$mm-easing-back:cubic-bezier(0.68, -0.55, 0.265, 1.55);
$mm-easing-bounce:cubic-bezier(0, 1.8, 1, 1.8);
$mm-easing-out:cubic-bezier(.41,.49,.57,1);

// Social
$theme-fb-color:#3a589b;
$theme-tw-color:#568bc9;
$theme-gp-color:#d6492f;
$theme-pt-color:#ca4638;
$theme-yt-color:#C3181E;
$theme-rss-color:#e19126;

$font-normal: 400;
$font-bold: 600;
$font-extrabold: 800;