.AjaxForm .form-control,
.AjaxForm .form-group,
.AjaxForm .item,
.transition-all {
  -webkit-transition: all 0.6s;
  -ms-transition: all 0.6s;
  -o-transition: all 0.6s;
  transition: all 0.6s;
}

.AjaxForm .form-control,
.AjaxForm .form-group,
.AjaxForm .item,
.transition-all {
  -webkit-transition: all 0.6s;
  -ms-transition: all 0.6s;
  -o-transition: all 0.6s;
  transition: all 0.6s;
}

.AjaxForm {
  // background-position: center;
  // background-repeat: no-repeat;
  // // background-image: url(/images/__wd__/preload.gif);
  // form {
  // 	float: left;
  // 	width: 100%;
  // 	position: relative;
  // 	div[class*=col] {
  // 		background: #fff;
  // 	}
  // }
  background-position:center 150px;
  &,
  form {
    transition: all .2s ease-in-out;
  }

  &.loading {
    background: url(/modules/cms_minimal/images/lazyLoader.gif) no-repeat center 150px;
    form {
      opacity: .3;
      &,
      & * {
        pointer-events: none !important;
      }
    }
  }
  .success,
  .errors {
    position: absolute;
    top: -21px;
    right: 5%;
    left: 5%;
    padding: 7px;
    color: #1b1b1b;
    cursor: pointer;
    opacity: 1 !important;
    z-index: 10;
    &:after {
      border: 0 none;
      border-radius: 50%;
      color: #fff;
      content: "x";
      display: block;
      font-size: 24px;
      height: 30px;
      line-height: 26px;
      position: absolute;
      right: -16px;
      text-align: center;
      top: -16px;
      width: 30px;
    }
  }
  .success {
    border: 1px solid #0c0;
    &:after {
      background-color: #0c0;
    }
  }
  .errors {
    border: 1px solid #d00;
    &:after {
      background-color: #d00;
    }
  }
  &.glyphicon {
    .success,
    .errors {
      &:after {
        content: "\e014" !important;
      }
    }
  }
  &.fa {
    .success,
    .errors {
      &:after {
        content: "\f00d" !important;
      }
    }
  }
}

.form-group.citta_provincia input.citta {
  float: left;
  width: 65%;
}

.form-group.citta_provincia input.prov {
  float: left;
  margin-left: 1%;
  width: 34%;
  text-align: center;
}

.form-group label {
  font-weight: normal;
}

.sweet-alert {
  .lead {
    font-size: 1.1em;
  }
}
