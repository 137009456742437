.box-news{
  &__title {
    font-weight: 700;
    font-size: 14px;
    margin: 0;
    color: $theme-color-2;
  }
}
.list-news {
  &--bg {
    background: linear-gradient(to bottom, $theme-color-4 0%, $theme-white 100%);
    padding: 20px 0 90px;
    .list-news__image { display: none; }
  }
  &__item{
    //margin-top: 50px;
    margin: 25px 0;
    &:hover {
      .list-news {
        &__image { 
          div { transform: scale(1.15); } 
        }
      }
    }
  }
  &__image { 
    width: 100%;
    height: 520px;
    overflow: hidden;
    margin-bottom: 35px;
    @include media-breakpoint-down(lg) { height: 430px; }
    @include media-breakpoint-down(md) { height: 310px; }
    @include media-breakpoint-down(xs) { 
      height: 210px; 
      margin-bottom: 20px;
    }
    div { 
			transition: transform 0.3s; 
			width: 100%;
      height: 100%;
      background-color: rgba($theme-color-2, .2);
		}
  }
  &__date {
    font-size: 14px;
    display: block;
    @include media-breakpoint-down(xs) { font-size: 12px; }
  }
  &__title {
    display: block;
    font-size: 25px;
    font-weight: 700;
    font-family: $theme-font-2;
    color: $theme-color-11;
    margin: 25px 0;
    @include media-breakpoint-down(xs) { 
      font-size: 18px; 
      margin: 15px 0;
    }
  }
  &__text {
    font-size: 14px;
    line-height: 21px;
    font-family: $theme-font-2;
    @include media-breakpoint-down(xs) { display: none; }
  }
  &__link {
    font-size: 14px;
    letter-spacing: 2px;
    display: inline-block;
    color: $theme-color-2;
    i { margin-left: 20px; }
  }
}
.t_page_home .list-news {
  @include media-breakpoint-down(xs) { padding: 0; }
}

.press {
  &__link {
    @extend .rivenditori__link;
    width: 100%;
    text-align: center;
    letter-spacing: 2px;
    @include media-breakpoint-down(md) { letter-spacing: 0; }
    @include media-breakpoint-down(sm) {
      padding-left: 8px!important;
      padding-right: 8px!important;
    }
  }
}
