.list-custom-tech {
  &__item {  
    margin-bottom: 60px;
    @include media-breakpoint-down(sm) { margin-bottom: 30px; }
    &:hover {
      .list-custom-tech__image {
        &__overlay { 
          opacity: .3; 
          background-color: $theme-black;
        }
      }
      .list-custom-tech__title {
        right: 105px;
        transition: all .4s ease-out;
        @include media-breakpoint-down(lg) { right: 65px; }
        @include media-breakpoint-down(md) { right: 35px; }
        @include media-breakpoint-down(sm) { right: inherit; }
        &--dark { 
          right: 160px; 
          @include media-breakpoint-down(lg) { right: 90px; }
        }
      }
    }
  }
  &__image {
    width: 100%;
    height: 475px;
    padding-right: 40px;
    margin-bottom: 0;
    @include media-breakpoint-down(lg) { height: 390px; }
    @include media-breakpoint-down(md) { height: 315px; }
    @include media-breakpoint-down(sm) { 
      height: 230px; 
      padding-right: 0;
    }
    @include media-breakpoint-down(xs) {  height: 155px; }
    div {
      width: 100%;
      height: 100%;
      position: relative;
      background-color: rgba($theme-color-2, .2);
      overflow: hidden;
      @include media-breakpoint-down(sm) { overflow: inherit; }
    }
    &__overlay { 
      position: absolute;
      background-color: transparent;
      width: 100%;
      height: 100%;
      top: 0;
      right: 0;
    }
  }
  &__title {
    color: $theme-white;
    font-size: 35px;
    font-family: $theme-font-2;
    font-weight: 500;
    position: absolute;
    right: -40px;
    top: 380px;
    max-width: 330px;
    transition: all .4s ease-in;
    z-index: 2;
    a { color: $theme-white; }
    &--dark {
      color: $theme-color-1;
      right: 15px;
      z-index: 1;
      line-height: 1.2;
    }
    @include media-breakpoint-down(lg) { top: 305px; }
    @include media-breakpoint-down(md) { 
      top: 240px; 
      font-size: 28px;
      max-width: 260px;
    }
    @include media-breakpoint-down(sm) { 
      font-size: 18px;
      color: $theme-color-1;
      position: relative;
      top: inherit;
      right: inherit;
      a { 
        color: $theme-color-1; 
        display: block;
        margin-top: 15px;
      }
      &--dark { display: none;  }
    }
  }
}