.box-world {
  height: 430px;
  padding-top: 50px;
  background: $theme-color-5;
  /*background-image: url('/images/galcust/1920x970/world.jpg');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;*/
  @include media-breakpoint-down(sm) { height: 330px; }
  @include media-breakpoint-down(xs) { 
    height: 205px; 
    padding-top: 20px;
    .container {
      padding-left: 0;
      padding-right: 0;
    }
  }
  &__title {
    font-weight: 700;
    font-size: 14px;
  }
  h3 {
    font-size: 50px;
    margin-top: 35px;
    margin-bottom: 0;
    font-family: $theme-font-2;
    font-weight: 300;
    strong { font-weight: 500; }
    @include media-breakpoint-down(xs) { 
      font-size: 30px; 
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
  span {
    display: block;
    font-size: 26px;
    font-weight: 300;
    margin-bottom: 35px;
    @include media-breakpoint-down(sm) { font-size: 30px; }
    @include media-breakpoint-down(xs) { 
      font-size: 16px; 
      margin-bottom: 0;
    }
  }
  p {
    font-size: 14px;
    line-height: 21px;
  }
  &__text {
    margin: 0 auto;
  }
  &__link {
    font-size: 14px;
    letter-spacing: 2px;
    display: inline-block;
    color: $theme-color-2;
    i { margin-left: 20px; }
  }
}