.modal-saviolab {
  z-index: 999999;
  .modal-lg { max-width: 680px; }
  .modal-header {
    border: none;
    align-items: center;
    svg {
      width: 152px;
      height: 40px;
      margin: 0 auto;
    }
    .close { margin-left: 0; }
  }
  .modal-body {
    font-size: 35px;
    font-family: $theme-font-2;
    font-weight: 100;
    display: block;
    color: $theme-color-7;
    p { margin-bottom: 20px; }
    iframe {
      margin: 0 auto;
    }
    @include media-breakpoint-down(xs) {
      font-size: 20px;
    }
  }
  &__button {
    border: none;
    padding: 10px 55px;
    border-radius: 60px;
    color: $theme-white;
    font-size: 23px;
    font-family: $theme-font-2;
    font-weight: 500;
    text-transform: uppercase;
    display: inline-block;
    background: linear-gradient(to bottom, #7e6f52 0%,#584932 100%);
    overflow: hidden;
    margin-bottom: 20px;
    cursor: pointer;
    &:before {
      background: $theme-color-1;
    }
    @include media-breakpoint-down(xs) {
      padding: 10px 55px;
      border-radius: 60px;
      font-size: 23px;
    }
  }
}
.modal-backdrop { z-index: 99999; }

#exampleModal,
#universalModal,
#exampleModal3{
  z-index: 100000;
  .modal-dialog{
    max-width: 50vw;
  }
}

.swal2-container{
  z-index: 999999;
}