.box-catalogue {
  padding: 130px 0 190px;
  @include media-breakpoint-down(sm) { padding: 60px 0; }
  @include media-breakpoint-down(xs) { padding: 0 0 60px 0; }
  &__thumb {
    width: 100%;
    height: 445px;
    margin-top: 200px;
    @include media-breakpoint-down(lg) { height: 370px; }
    @include media-breakpoint-down(md) { height: 270px; }
    @include media-breakpoint-down(sm) { 
      height: 195px; 
      margin-top: 100px;
    }
  }
  &__body {
    padding-left: 40px;
    @include media-breakpoint-down(sm) { padding-left: 0; } 
  }
  &__title {
    font-weight: 700;
    font-size: 14px;
  }
  h3 {
    font-size: 50px;
    margin-top: 45px;
    margin-bottom: 0;
    font-family: $theme-font-2;
    font-weight: 300;
    strong { font-weight: 500; }
    @include media-breakpoint-down(lg) { font-size: 53px; }
    @include media-breakpoint-down(md) { 
      font-size: 45px; 
      margin-top: 25px;
    }
    @include media-breakpoint-down(sm) { 
      margin: 15px 0;
      font-size: 36px;
    }
    @include media-breakpoint-down(xs) { 
      font-size: 40px; 
      line-height: 38px;
      margin: 35px 0 0;
    }
  }
  span {
    display: block;
    font-size: 26px;
    font-weight: 300;
    margin-bottom: 75px;
    @include media-breakpoint-down(md) { 
      font-size: 24px; 
      margin-bottom: 40px;
    }
    @include media-breakpoint-down(sm) { 
      margin-bottom: 0; 
      font-size: 22px;
    }
    @include media-breakpoint-down(xs) { 
      font-size: 16px;
      margin-bottom: 20px; 
    }
  }
  p {
    font-size: 14px;
    line-height: 21px;
  }
  &__link {
    margin-top: 30px;
    display: inline-block;
    background: $theme-color-2;
    color: $theme-white;
    padding: 10px 75px;
    font-size: 12px;
    margin-bottom: 65px;
    &.hvr-sweep-to-right:before {
      background: $theme-color-1;
    }
    @include media-breakpoint-down(md) { margin-bottom: 35px; }
    @include media-breakpoint-down(sm) { margin: 25px 0; }
    @include media-breakpoint-down(xs) { 
      width: 100%; 
      text-align: center;
    }
  }
  &__header {
    width: 100%;
    height: 260px;
    @include media-breakpoint-down(lg) { height: 210px; }
    @include media-breakpoint-down(md) { height: 155px; }
    @include media-breakpoint-down(sm) { height: 120px; }
    @include media-breakpoint-down(xs) { height: 155px; }
  }
}

.box-catalogue-home {
  &__display {
    //background-color: $theme-color-12;
    padding: 0 15px;
    position: relative;
    @include media-breakpoint-down(sm) {
      padding: 50px 15px;
    }
    @include media-breakpoint-down(xs) {
      padding: 30px 15px;
    }

    &::before {
      content: "";
      position: absolute;
      width: 100%;
      left:0;
      background-color: $theme-color-12;
      z-index: -1;

    }
    &::before{
      top: 50px;
      bottom: 50px;
      @include media-breakpoint-down(md) {
        top: 0;
        bottom:0;
      }
    }

    &--config {
      margin: 0;
      .saviolab__display__title {
        color: $theme-color-20;
      }
    }

    p {
      font-size: 16px;
      font-family: $theme-font-2;
      font-weight: $font-normal;
      color: $theme-color-7;
      @include media-breakpoint-down(sm) {
        text-align: center;
        margin-bottom: 50px;
      }
      @include media-breakpoint-down(lg) {
        font-size: 16px;
      }
    }
    &__title { 
      font-weight: 400;
      font-size: 14px;
      font-family: "Montserrat", sans-serif;
      text-transform: lowercase;
      
      @include media-breakpoint-down(sm) {
        text-align: center;
      }
    }

    h3 {
      font-size: 50px;
      line-height: 50px;
      margin-bottom: 10px;
      font-family: $theme-font-2;
      font-weight: 600;
      color: $theme-color-11;
      @include media-breakpoint-down(sm) { 
        text-align: center;
      }
      @include media-breakpoint-down(xs) { 
        font-size: 35px; 
        line-height: 35px;
        margin-top: 10px;
      }
    }

    span {
      font-family: "Montserrat", sans-serif;
      display: block;
      font-size: 26px;
      font-weight: 500;
      margin-bottom: 15px;
      @include media-breakpoint-down(md) { font-size: 24px; }
      @include media-breakpoint-down(sm) { margin-bottom: 30px; text-align: center; }
      @include media-breakpoint-down(xs) {  font-size: 16px; }
    }

    .col-md-6{
      display: flex;
      justify-content: center;
      flex-direction: column;
    }

    &__button{
      color: white !important;
      background: $theme-color-20 !important;
  
      &:hover {
        background: rgba($theme-color-20, .8) !important;
      }
    }
  }
}