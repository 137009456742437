.cast-ami-ben {
   $ff-light:           'Aeonik-Light', serif;
   $ff-regular:         'Aeonik-Regular', serif;
   $ff-medium:          'Aeonik-Medium', serif;
   $ff-bold:            'Aeonik-Bold', serif;
   $text-intro:         #b68150;
   $bg-intro:           #f5eae2;

   $title-progetto:     #36563d;
   $text-progetto:      #9bae88;
   $bg-progetto:        #eff2ec;
   $border-color:       #dfe5d9;

   &__intro{
      padding: 80px 30px;
      background-color: $bg-intro;
      text-align: center;
      h1 {
         font-size: 35px;
         color: $text-intro;
         font-family: $ff-medium;
         @media screen and (min-width: 1024px){
            font-size: 50px;
         }
      } 
      p{
         margin: 0;
         font-size: 25px;
         font-family: $ff-light;
         @media screen and (min-width: 1024px){
            font-size: 30px;
         }
      }
   }

   &__gallery{
      display: flex;
      flex-wrap: wrap;
      &__item {
         width: 100%;
         @media screen and (min-width: 1024px){
            width: 50%;
         }
      }
   }

   &__text {
      padding: 50px 30px;
      text-align: center;

      display: flex;
      align-items: center;
      flex-direction: column;
      
      border-bottom:2px solid $border-color;

      &__svg {
         width: 100%;
         margin: 20px 0 40px;
         max-width: 150px !important;
         img {
            width: 100%;
         }
      }

      p{
         font-family: $ff-medium;
         line-height: 1.3em;
         font-size: 16px; 
         letter-spacing: 2px;
         /*@media screen and (min-width: 1024px){
            font-size: 16px;
         }*/
      }

      strong{
         font-family: $ff-bold;
      }

      &__small {
         font-family: $ff-light !important;
         //font-size: 18px !important;
         /*@media screen and (min-width: 1024px){
            font-size: 28px !important;
         }*/
      }

      &__small:last-child{
         font-family: $ff-medium !important;
         //font-size: 14px !important;
         /*@media screen and (min-width: 1024px){
            font-size: 22px !important;
         }*/
      }
   }

   &__progetto {
      $this: &;

      h2{
         text-align: center;
         font-family: $ff-bold;
         font-size: 30px;
         margin-top: 60px;
         color: $title-progetto;
      }
      &__block{
         display: flex;
         align-items: center;
         flex-direction: column;
         padding: 40px 30px;
         &:first-of-type{
            background: linear-gradient(180deg, rgba(white,1) 0%, rgba(white,1) 110px, rgba($bg-progetto,1) 110px);
            #{$this}__title {
               color: $text-progetto;
            }
         }
         &:last-of-type{
            background: linear-gradient(180deg, rgba(white,1) 0%, rgba(white,1) 110px, rgba($bg-intro,1) 110px);
            #{$this}__title {
               color: $text-intro;
            }
         }
      }


      &__svg{
        max-width: 150px;
        width: 100%; 
        margin-bottom:30px;
        img {
            width: 100%;
         }
      }

      &__title {
         font-family: $ff-bold;
         font-size: 22px; 
         margin-bottom:30px;
         /*@media screen and (min-width: 1024px){
            font-size: 30px;
         }*/
         
      }

      p {
         font-family: $ff-light !important;
         font-size: 16px !important;
         text-align: center;
         max-width: 980px;
         padding: 0 30px;
         margin: 0;
         letter-spacing: 2px;
         /*@media screen and (min-width: 1024px){
            font-size: 24px !important;
         }*/
      }
   }

   &__btns{
      margin-top: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      a {
         margin: 10px;
         padding: 7px 0 !important;
         width: 200px !important;
         text-align: center;
      }
   }

   &__footer {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      padding: 50px 30px;
      border-bottom: 2px solid $border-color;

      h3 {
         font-family: $ff-bold;
         font-size: 16px; 
         margin-bottom:20px;
         /*@media screen and (min-width: 1024px){
            font-size: 30px;
         }*/
      }

      p {
         text-align: center;
         font-family: $ff-light !important;
         font-size: 16px;
         margin-bottom: 0;
      }
   }


   &__loghi {
      padding: 30px;
      &__top {
         max-width: 300px;
         width: 100%;
         margin:0 auto;
      }

      ul{
         display: flex;
         align-items: center;
         justify-content: center;
         flex-wrap: wrap;
         list-style: none;
         padding: 0;
         margin-top:20px;

         li {
            margin: 20px 0;
            padding: 0 35px;
            height: 100px;
            text-align: center;
            position: relative;
            &:nth-child(3)::before,
            &:nth-child(3)::after{
               content:"";
               display: block;
               width: 2px;
               height: 80px;
               position: absolute;
               background-color: $border-color;
               top: 50%;
               transform: translateY(-50%);
            }

            &:nth-child(3)::before {
               left:0;
            }

            &:nth-child(3)::after {
               right:0;
            }

            img {
               height: 100%;
               display: inline-block;
            }
         }
      }
   }

   &__page {
      
      h1, h2, h3, h4 {
         font-family: $ff-bold;
         margin-top:20px;
      }



      p, li, a {
         font-family: $ff-regular;
         font-size: 16px;
         line-height: 1.5em !important;
         text-align: justify;
      }

      a {
         text-decoration: underline;
      }

      p img{
         display: block;
         max-width: 300px; 
         margin:0 auto;
         padding-bottom: 20px;

         @media screen and (min-width: 768px){
            padding-left: 20px; 
            float: right;
            display: inline-block;
         }
      } 
   }

}  