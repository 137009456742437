.box-company {
  padding: 60px 0;
  //min-height: 772px;
  @include media-breakpoint-down(sm) {
    //min-height: 600px;
    //.container { height: 590px; }
  }
  @include media-breakpoint-down(xs) {
    padding: 50px 0;
    //min-height: 700px;
    .container { height: auto; }
  }
  &__img {
    width:100%;
    max-width: 300px;
    padding:50px 0;
  }

  &__button{
    color: white !important;
    background: $theme-color-28 !important;

    &:hover {
      background: rgba($theme-color-28, .8) !important;
    }
  }

  &__image {
    /*width: 358px;
    height: 652px;
    position: absolute;
    top: 0;
    right: 0;
    @include media-breakpoint-down(sm) {
      width: 245px;
      height: 450px;
      top: inherit;
      bottom: 0;
    }
    @include media-breakpoint-down(xs) { 
      width: 100%;
      height: 350px;
      top: inherit;
      bottom: inherit;
      right: inherit;
      position: relative;
      background: center bottom;
    }*/
  }
  &__body {
    position: relative;
    z-index: 2;
    padding-top: 50px;
    @include media-breakpoint-down(md) {  
      padding-top: 0; 
    }
  }
  &__title {
    font-weight: 400;
    font-family: "Montserrat", sans-serif;
    text-transform: lowercase;
    font-size: 20px;
  }
  h3 {
    font-size: 50px;
    line-height: 50px;
    margin-bottom: 10px;
    font-family: $theme-font-2;
    font-weight: 600;
    @include media-breakpoint-down(xs) { 
      font-size: 35px; 
      line-height: 35px;
      margin-top: 10px;
    }
    &.violet {
      color: $theme-color-26;
    }
    &.brown {
      color: $theme-color-27;
    }
    span {
      font-weight: 300;
      font-size: 70%;
      display: inline;
    }
  }
  span {
    font-family: "Montserrat", sans-serif;
    display: block;
    font-size: 30px;
    font-weight: 500;
    margin-bottom: 30px;
    @include media-breakpoint-down(md) { font-size: 24px; }
    @include media-breakpoint-down(sm) { margin-bottom: 30px; }
    @include media-breakpoint-down(xs) {  font-size: 16px; }
  }
  p {
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    line-height: 1.5em;
    margin-bottom: 0;
    margin-bottom:30px;
  }
  &__link {
    font-size: 14px;
    letter-spacing: 2px;
    display: inline-block;
    color: $theme-color-2;
    margin-bottom: 40px;
    i { margin-left: 20px; }
    @include media-breakpoint-down(sm) { float: left; }
    @include media-breakpoint-down(xs) {
      float: none;
    }
  }
}