.breadcrumbs {
  padding: 37px 0;
  background: $theme-color-4;
  margin-top: 240px;
  @include media-breakpoint-down(xs) {
    margin-top: 130px;
  }
  .breadcrumb { 
    background: transparent;
    font-size: 16px;
    color: $theme-color-2;
    font-family: $theme-font-2;
    padding: 0;
    margin: 0;
    font-weight: 400;
    a {
      font-size: 16px;
      font-family: $theme-font-2;
      color: $theme-color-2;
      padding-right: 5px;
      &:hover { text-decoration: underline; }
      &:after {
        content: '.';
        float: right;
        margin-right: 30px;
      }
    }
    .active {
      font-weight: 700;
    }
  }
}
/*.t_catalog_product .breadcrumbs { 
  background: $theme-white; 
  @include media-breakpoint-down(xs) { 
    padding: 30px 0; 
    .breadcrumb { 
      font-size: 13px;
      font-weight: 300;
      a {
        font-size: 13px;
        font-weight: 300;
      }
    }
  }
}*/