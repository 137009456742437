.visual-catalog {
  width: 100%;
  height: 795px;
  padding: 0;
  margin-bottom: 0!important;
  position: relative;
  &__text {
    position: absolute;
    bottom: 85px;
    left: 50%;
    transform: translateX(-50%);
    font-family: $theme-font-2;
    font-weight: 500;
    color: $theme-white;
    font-size: 24px;
    width: 100%;
    text-align: center;
    @include media-breakpoint-down(xs) {
      bottom: 15px;
      font-size: 16px;
    } 
  }
  &--video { 
    overflow: hidden;
    position: relative;
    height: 570px;
    margin-top: 240px;
    margin-bottom: -240px !important;
    @include media-breakpoint-down(xs) {
      margin-top: 130px;
      margin-bottom: -130px !important;
    }
    @include media-breakpoint-down(lg) { height: 430px; }
    video {
      object-fit: cover;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  @media (max-width: 1350px) {
    &--video { 
      height: 530px;
    }
  }
  @include media-breakpoint-down(lg) { 
    height: 450px; 
    &--video { 
      height: 430px;
    }
  }
  @include media-breakpoint-down(md) { 
    height: 320px; 
    /*&--video { 
      min-height: 350px; 
      height: auto;
    }*/
  }
  @include media-breakpoint-down(sm) { 
    height: 265px; 
    &--video { 
      height: 255px; 
    }
  }
  @include media-breakpoint-down(xs) { 
    height: 170px; 
    &--video { 
      height: 250px; 
    }
  }
  &__item {
    width: 100%;
    height: 795px;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    position: relative;
    @include media-breakpoint-down(lg) { height: 450px; }
    @include media-breakpoint-down(md) { height: 350px; }
    @include media-breakpoint-down(sm) { height: 265px; }
    @include media-breakpoint-down(xs) { height: 170px; }
  }
  .slick-dots {
    bottom: 70px;
    left: 50%;
    transform: translateX(-50%);
    text-align: left;
    @include media-breakpoint-down(sm) { display: none!important; }
    li {
      width: 178px;
      height: 3px;
      @include media-breakpoint-down(lg) { width: 158px; } 
      @include media-breakpoint-down(md) { width: 98px; } 
      button {
        width: 100%;
        height: 100%;
        &:before {
          content: '';
          background-color: $theme-white;
          width: 100%;
          height: 100%;
          opacity: 1;
        }
        &:after {
          content: "\e252";
          display: inline-block;
          font-family: 'Glyphicons Halflings';
          font-style: normal;
          font-weight: normal;
          line-height: 1;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          color: transparent;
          width: 14px;
          height: 7px;
          position: absolute;
          top: -15px;
          left: 50%;
          transform: translateX(-50%);
          font-size: 14px;
        }
      }
      &.slick-active {
        button {
          &:before { background-color: $theme-color-1; }
          &:after {
            color: $theme-color-1;
          }
        }
      }
    }
  }
}