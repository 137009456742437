.contacts {
  &__wrap {
    padding: 0;
  }
  &__item {
    margin-top: 160px;
    @include media-breakpoint-down(sm) { margin-top: 30px; }
  }
  &__name {
    font-size: 25px;
    font-family: $theme-font-2;
    font-weight: 600;
    margin-bottom: 25px;
    @include media-breakpoint-down(xs) { font-size: 18px; }
  }
  &__text {
    display: block;
    font-size: 14px;
    font-weight: 700;
    line-height: 21px;
    margin-bottom: 20px;
  }
  &__address {
    font-size: 14px;
    margin-bottom: 30px;
    svg {
      float: left;
      margin: 0 10px 30px 0;
    }
  }
  &__link {
    display: block;
    font-size: 14px;
    margin-bottom: 8px;
  }
}