$theme-white: #fff;
.resarea{
  &__login-modal {
    .modal-dialog { max-width: 700px; }
  }
  &__loadingOver{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom:0;
    background: rgba($theme-white,.8);
    z-index: 99;
    &>span{
      position: absolute;
      left: 50%;
      top: 50%;
      transform:translate(-50%,-50%);
      font-size: 30px;
    }
  }
  &__form-title {
    span {
      font-size: 14px;
      font-weight: 300;
      display: block;
      width: 100%;
    }
    a {
      font-size: 22px;
      font-weight: 300;
    }
  }
  &__login{
    padding-left: 0;
    padding-right: 0;
    .alert {
      margin-top: 20px;
    }
    &__lostpw {
      font-size: 14px;
      font-weight: 300;
    }
    &__button{
      /*width:100%;*/
      cursor: pointer;
    }
  }
  &__registration {
    &__form {
      .form-check-input { 
        margin-left:0; 
        position: relative;
        &.is-invalid + .form-check-label a { color: $red; }
      }
      button { cursor: pointer; }
    }
  }
  &__lost_pass {
    label { 
      display: block; 
      @include media-breakpoint-down(xs) { display: inline-block; }
    }
    &__button {
      cursor: pointer;
    }
  }
  &__exit {
    &.btn-primary {
      text-transform: uppercase;
      font-size: 12px;
      font-family: $theme-font-2;
      background: $theme-color-1;
      color: $theme-white;
      overflow: hidden;
      border-radius: 15px;
      float: right;
      border-color: $theme-color-1;
      padding: 7px 20px;
      letter-spacing: 2px;
      i { margin-left: 15px; }
      &.hvr-sweep-to-right:before {
        background-color: $theme-white;
        border-color: $theme-white;
      }
      &:hover { 
        color: $theme-color-1; 
        border-color: $theme-color-1;
      }
      &:focus { box-shadow: none; }
    }
  }
  &__accordion {
    .card {
      background-color: transparent;
      border-radius: 0;
      border: none;
      &:first-child {
        border-top: 1px solid $theme-color-1;
      }
    }
    .card-header {
      background: transparent;
      border-radius: 0;
      border-bottom: 1px solid $theme-color-1;
      @include media-breakpoint-down(xs) { padding:  0.75rem 0.3rem; }
      button {
        font-size: 22px;
        font-weight: 300;
        font-family: $theme-font-2;
        width: 100%;
        text-align: left;
        &.collapsed {
          i {
            &:before { content: "\e252"; }
          }
        }
        @include media-breakpoint-down(xs) { 
          font-size: 18px; 
          padding: 0.375rem 0.20rem;
        }
      }
    }
    .card-body { 
      padding: 1.25rem 2rem; 
      border-bottom: 1px solid $theme-color-1;
      @include media-breakpoint-down(xs) {  padding: 1rem .3rem; }
    }

    .table-responsive { margin: 15px 0 25px; }
  }
  &__table {
    margin-bottom: 0;
    font-size: 14px;
    &__collapse {
      transition: all .3s ease-in-out;
    }
  }
}
