.box-triennale {
  position: relative;
  background: url('/images/triennale.jpg') center / cover;
  // padding: 50px 20px;
  // filter: brightness(0.5);
  max-height: 400px;

  @media screen and (max-width:991px) {
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5); // Regola l'opacità qui
    z-index: 1;
  }

  // Assicurati che il contenuto sia posizionato sopra lo pseudo-elemento
  &>* {
    position: relative;
    z-index: 2;
  }

  // %margine {
  //   margin-bottom: 30px;

  //   @media screen and (min-width:992pxpx) {
  //     margin-bottom: 0;
  //   }
  // }

  // &__logo {
  //   flex: 1 0 auto;
  //   display: flex;
  //   //@extend %margine;

  //   img {
  //     margin: auto;
  //   }
  // &__logo{
  //   height: 150px;
  //   width: 150px;
  //   @media screen and (max-width:991px) {
  //     height: 100px;
  //     width: 100px;
  //   }
  // }

  &__text{
    color: white;
    font-size: 30px;
    font-family: $theme-font-2;
    text-align: right;
    line-height: 1.5;
  }

  //   @media screen and (max-width:992px) {
  //     display: block;
  //   }
  // }

  &__img {
    height: 100%;
    img{
      height: 100%;
      object-fit: cover;
      width: 100%;
      max-height: 400px;

      @media screen and (max-width:991px) {
        display: none;
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: row;
    //align-content: center !important;
    //justify-content: center;
    align-items: center;
    column-gap: 20px;
    padding: 40px;
    // justify-content: space-between;
    @media screen and (max-width:991px) {
      flex-direction: column;
    }
  }
}