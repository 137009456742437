.list-operation {
  &__item { margin-bottom: 15px }
  
  &__body {
    background-color: $theme-color-19;
    box-shadow: 1px 1px 5px rgba($theme-color-2, .2);
    padding: 40px 25px;
    font-size: 16px;
    color: $theme-color-7;
    font-family: $theme-font-2;
    h3{
      font-size:16px;
    }
    svg { 
      margin-bottom: 60px;
      width: 80px;
      
      @include media-breakpoint-down(xs) { margin-bottom: 30px; }
      path {
        fill: $theme-color-16;
      }
    }
    @include media-breakpoint-down(xs) {
      padding: 20px 15px;
    }
  }
}

.saviolab__operation--config{
  .list-operation__body {
    background-color:$theme-color-23 !important;
  }
  
  path {
    fill: $theme-color-22 !important;
  }
} 