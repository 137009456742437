.visual{
  width: 100%;
  //height: 100vh;
  padding: 0;
  //margin-top: -240px;
  @include media-breakpoint-down(xs) { 
    // height: 60vh; 
    margin-top: 0;
  }
  &__item {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    position: relative;
    overflow:hidden;
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
      @include media-breakpoint-down(sm) { 
        max-width: 130%;
      }
    }
  }
  .logo_german{
    position: absolute;
    text-align: right;
    top: 1%;
    right: 5%;
    
    

    img{
      width: 20%;
      background-color: rgba(0, 0, 0, 0.5);
      padding: 20px;
      @include media-breakpoint-down(sm) { 
        width: 30% !important;
      }
    };
  }
  &__body {
    position: absolute;
    top: 175px;
    left: 40px;
    font-size: 40px;
    font-family: $theme-font-2;
    font-weight: 400;
    color: $theme-white;
    padding: 65px 0 0 130px;
    background-image: url('/images/bg_visual_home.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 825px;
    height: 280px;
    text-shadow: 0px 5px 10px $theme-black;
    strong { font-weight: 600; }
    svg {
      fill: $theme-white;
      width: 305px;
      height: 77px;
      margin-bottom: 10px;
    }
  }
} 