.footer {
  &__up {
    padding: 30px 0;
    background: $theme-color-3;
    strong {
      font-size: 15px;
      font-weight: 600;
      font-family: $theme-font-2;
      display: block;
      margin-bottom: 10px;
    }
    p { 
      font-size: 12px;
      font-weight: 300;
      font-family: $theme-font-2;
      margin-bottom: 0;
    }
    a {
      font-size: 12px;
      font-weight: 300;
      letter-spacing: 2px;
      display: inline-block;
      i { margin-left: 7px; }
    }
  }
  &__bottom {
    padding: 20px 0;
    a {
      margin: 0 12px;
      font-size: 13px;
      font-weight: 300;
      font-family: $theme-font-2;
    }
  }
  &__social {
    @include media-breakpoint-down(sm) { margin-top: 20px; }
    svg {
      fill: $theme-color-1;
      margin-left: 5px;
    }
    a:hover svg { fill: $theme-black; }
  }
}