.saviolab {
  &__intro {
    //background: linear-gradient(to bottom, $theme-color-4 0%, $theme-white 100%);
    background-color: $theme-color-15;
    padding: 70px 15px 30px;
    margin-top: 240px;

    @include media-breakpoint-down(xs) {
      margin-top: 130px;
    }

    svg { 
      max-height: 60px;
    }

    @include media-breakpoint-down(xs) { 
      padding: 30px 0;
      svg { 
        width: 90%;
      }
    }

    &__title {
      font-size: 70px;
      text-transform: uppercase;
      font-weight: $font-bold;
    }
    &__text {
      font-size: 30px; 
      font-family: $theme-font-1;
      font-weight: $font-normal; 
      //letter-spacing: 5px;
      margin-top: 20px;
      margin-bottom: 20px;
      display: block;
      color: $theme-color-1;
      @include media-breakpoint-down(sm) {
        // margin-top: 0;
        margin-bottom: 30px;
        font-size: 24px;
      }
    }

    &__button{
      background-color: $theme-color-16;
    }

    &__label {
      color: $theme-color-16;
      font-size: 20px;
      font-family: $theme-font-2;
      font-weight: $font-extrabold;
      margin-bottom: 25px;
      display: block;
      @include media-breakpoint-down(xs) { font-size: 26px; }
    }

    &--config {
      background-color: $theme-color-21;
    }
  }
  &__content {
    padding-top: 70px;
    font-size: 16px;
    font-family: $theme-font-2;
    font-weight: $font-normal;
    color: $theme-color-7;
    text-align: center;
    background-repeat: no-repeat;
    background-position: bottom center;
    background-size: contain;

    &__image{
      margin-top: -100px;
      @include media-breakpoint-down(lg) { margin-top: -30px; }
    }
    h3 {
      font-size: 25px;
      font-weight: $font-bold;
      margin-bottom: 40px;
      color: $theme-color-17;
      font-family: $theme-font-2;
      @include media-breakpoint-down(xs) { 
        margin-bottom: 25px;
        font-size: 40px;
      }
    }
  }
  &__map {
    padding: 0;
    &__title {
      font-size: 25px;
      font-family: $theme-font-2;
      font-weight: $font-bold;
      color: $theme-color-18;
      margin: 70px 0;
      @include media-breakpoint-down(md) { 
        font-size: 35px;
        //margin-bottom: 100px;
      }
      @include media-breakpoint-down(xs) { margin-bottom: 30px; }
    }
  }
  &__operation {
    padding: 0 15px 70px;
    &--config{
      padding: 0 15px 20px;
    }

    &--cosasapere {
      position: relative;
      background: linear-gradient(0deg, $theme-color-25 0%, $theme-color-25 39.9%, $theme-white 40%, $theme-white 100%);
      @include media-breakpoint-down(sm) { background: none; }
    }
    &__title { 
      @extend .saviolab__map__title; 
      color: $theme-color-16;
      &--config {
        color: $theme-color-20;
      }
      &--cosasapere{
        color: $theme-color-17 !important;
      }
    }
    @include media-breakpoint-down(sm) {
      padding: 50px 15px;
    }
    /*@include media-breakpoint-down(xs) {
      padding: 30px 15px;
    }*/
  }
  &__display {
    padding: 0 15px;
    position: relative;


    @include media-breakpoint-down(sm) {
      padding: 50px 15px;
    }
    @include media-breakpoint-down(xs) {
      padding: 30px 15px;
    }

    &::before,
    &::after {
      content: "";
      position: absolute;
      width: 100%;
      left:0;
      height: 1px;
      background-color: $theme-color-18;
      z-index: -1;
      @include media-breakpoint-down(md) {
        display: none;
      }
    }
    &::before{
      top: 50px;
      @include media-breakpoint-down(md) {
        top: 0;
      }
    }
    &::after{
      bottom: 50px;
      @include media-breakpoint-down(md) {
        bottom: 0;
      }
    }

    &--config {
      margin: 70px 0;
      .saviolab__display__title {
        color: $theme-color-20;
      }
    }

    p {
      font-size: 16px;
      font-family: $theme-font-2;
      font-weight: $font-normal;
      color: $theme-color-7;
      @include media-breakpoint-down(sm) {
        text-align: center;
        margin-bottom: 50px;
      }
      @include media-breakpoint-down(lg) {
        font-size: 16px;
      }
    }
    &__title { 
      @extend .saviolab__map__title; 
      margin: 0 0 30px; 
      
      @include media-breakpoint-down(sm) {
        text-align: center;
      }
    }
    .col-md-6{
      display: flex;
      justify-content: center;
      flex-direction: column;
    }
  }
  &__experience {
    // background: $theme-color-14;
    // padding: 90px 15px;
    // @include media-breakpoint-down(xs) { padding: 50px 15px; }
    margin: 30px 0 50px;
    &__title { 
      //@extend .saviolab__map__title; 
      // margin-bottom: 40px;
      margin-bottom: 25px;
      color: $theme-color-16;
      font-size: 25px;
      font-family: $theme-font-2;
      font-weight: $font-extrabold;
    }
    &__button{
      background-color: $theme-color-16;
    }
  }
  &__iframe { padding: 0; }
  &__fixedLink {
    position: fixed;
    bottom: 50px;
    right: 0;
    background: $theme-white;
    width: 235px;
    height: 80px;
    color: $theme-white;
    font-size: 13px;
    font-family: $theme-font-2;
    font-weight: 500;
    z-index: 3;
    text-align: center;
    
    &:hover { color: $theme-white; }
    svg {
      width: 151px;
      height: 40px;
      margin: 8px auto;
      @include media-breakpoint-down(xs) {
        width: calc(100% - 16px);
        margin: 8px;
      }
    }

    
    span {
      display: block;
      width: 100%;
      height: 25px;
      line-height:25px;
      text-transform: uppercase;
      background: linear-gradient(to right, rgba(122,106,80,1) 0%,rgba(89,74,51,1) 100%);
      text-align: center;
      @include media-breakpoint-down(xs) {
        font-size: 11px;
        
      }
    }
    @include media-breakpoint-down(xs) {
      bottom: 0;
      width: 50%;
    }
    &--top {
      bottom: 150px;
      svg {
        width: 180px;
        margin: 8px auto;
        @include media-breakpoint-down(xs) {
          width: calc(100% - 16px);
          margin: 8px;
        }
      }
      @include media-breakpoint-down(xs) {
        border-right: 1px solid #cccccc;
        bottom: 0;
        left: 0;
        width: 50%;
      }
    }

    &--100{
      @include media-breakpoint-down(xs) {
        border-right: none;
        bottom: 0;
        left: 0;
        width: 100%;
      }
    }
  }
}

.spa .saviolab__fixedLink { 
  width: 260px; 
  @include media-breakpoint-down(xs) {
    bottom: 0;
    width: 100%;
  }
}

.spa .saviolab__fixedLink { 
  width: 260px; 
  @include media-breakpoint-down(xs) {
    bottom: 0;
    width: 100%;
  }
}