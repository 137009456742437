@font-face {
    font-family: 'Aeonik-Air';
    src: url('../fonts/Aeonik-Air.eot'); /* IE9 Compat Modes */
    src: url('../fonts/Aeonik-Air.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/Aeonik-Air.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/Aeonik-Air.woff') format('woff'); /* Pretty Modern Browsers */
  }
  
  @font-face {
    font-family: 'Aeonik-AirItalic';
    src: url('../fonts/Aeonik-AirItalic.eot'); /* IE9 Compat Modes */
    src: url('../fonts/Aeonik-AirItalic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/Aeonik-AirItalic.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/Aeonik-AirItalic.woff') format('woff'); /* Pretty Modern Browsers */
  }
  
  @font-face {
    font-family: 'Aeonik-Black';
    src: url('../fonts/Aeonik-Black.eot'); /* IE9 Compat Modes */
    src: url('../fonts/Aeonik-Black.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/Aeonik-Black.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/Aeonik-Black.woff') format('woff'); /* Pretty Modern Browsers */
  }
  
  @font-face {
    font-family: 'Aeonik-BlackItalic';
    src: url('../fonts/Aeonik-BlackItalic.eot'); /* IE9 Compat Modes */
    src: url('../fonts/Aeonik-BlackItalic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/Aeonik-BlackItalic.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/Aeonik-BlackItalic.woff') format('woff'); /* Pretty Modern Browsers */
  }
  
  @font-face {
    font-family: 'Aeonik-Bold';
    src: url('../fonts/Aeonik-Bold.eot'); /* IE9 Compat Modes */
    src: url('../fonts/Aeonik-Bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/Aeonik-Bold.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/Aeonik-Bold.woff') format('woff'); /* Pretty Modern Browsers */
  }
  
  @font-face {
    font-family: 'Aeonik-BoldItalic';
    src: url('../fonts/Aeonik-BoldItalic.eot'); /* IE9 Compat Modes */
    src: url('../fonts/Aeonik-BoldItalic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/Aeonik-BoldItalic.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/Aeonik-BoldItalic.woff') format('woff'); /* Pretty Modern Browsers */
  }
  
  @font-face {
    font-family: 'Aeonik-Light';
    src: url('../fonts/Aeonik-Light.eot'); /* IE9 Compat Modes */
    src: url('../fonts/Aeonik-Light.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/Aeonik-Light.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/Aeonik-Light.woff') format('woff'); /* Pretty Modern Browsers */
  }
  
  @font-face {
    font-family: 'Aeonik-LightItalic';
    src: url('../fonts/Aeonik-LightItalic.eot'); /* IE9 Compat Modes */
    src: url('../fonts/Aeonik-LightItalic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/Aeonik-LightItalic.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/Aeonik-LightItalic.woff') format('woff'); /* Pretty Modern Browsers */
  }
  
  @font-face {
    font-family: 'Aeonik-Medium';
    src: url('../fonts/Aeonik-Medium.eot'); /* IE9 Compat Modes */
    src: url('../fonts/Aeonik-Medium.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/Aeonik-Medium.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/Aeonik-Medium.woff') format('woff'); /* Pretty Modern Browsers */
  }
  
  @font-face {
    font-family: 'Aeonik-MediumItalic';
    src: url('../fonts/Aeonik-MediumItalic.eot'); /* IE9 Compat Modes */
    src: url('../fonts/Aeonik-MediumItalic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/Aeonik-MediumItalic.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/Aeonik-MediumItalic.woff') format('woff'); /* Pretty Modern Browsers */
  }
  
  @font-face {
    font-family: 'Aeonik-Regular';
    src: url('../fonts/Aeonik-Regular.eot'); /* IE9 Compat Modes */
    src: url('../fonts/Aeonik-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/Aeonik-Regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/Aeonik-Regular.woff') format('woff'); /* Pretty Modern Browsers */
  }
  
  @font-face {
    font-family: 'Aeonik-RegularItalic';
    src: url('../fonts/Aeonik-RegularItalic.eot'); /* IE9 Compat Modes */
    src: url('../fonts/Aeonik-RegularItalic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/Aeonik-RegularItalic.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/Aeonik-RegularItalic.woff') format('woff'); /* Pretty Modern Browsers */
  }
  
  @font-face {
    font-family: 'Aeonik-Thin';
    src: url('../fonts/Aeonik-Thin.eot'); /* IE9 Compat Modes */
    src: url('../fonts/Aeonik-Thin.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/Aeonik-Thin.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/Aeonik-Thin.woff') format('woff'); /* Pretty Modern Browsers */
  }
  
  @font-face {
    font-family: 'Aeonik-ThinItalic';
    src: url('../fonts/Aeonik-ThinItalic.eot'); /* IE9 Compat Modes */
    src: url('../fonts/Aeonik-ThinItalic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/Aeonik-ThinItalic.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/Aeonik-ThinItalic.woff') format('woff'); /* Pretty Modern Browsers */
  }