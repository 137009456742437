.box-trendcor {
  background: url('/images/banner_background.jpg') center / cover;
  padding: 50px 20px;

  %margine {
    margin-bottom: 30px;
    
    @media screen and (min-width:768px){
      margin-bottom: 0;
    }
  }

  &__logo {
    flex: 1 0 auto;
    display:flex;
    @extend %margine;

    img {
      margin: auto;
    }

    @media screen and (min-width:768px){
      display:block;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;

    @media screen and (min-width:768px){
      display: flex;
      flex-direction: row;
      align-content: center;
      justify-content: space-between;
    }
  }

  &__text {
    display: flex;
    @extend %margine;

    p{
      margin: auto !important;
      font-size: 24px;
      font-family: $theme-font-2;
      text-align: center;
      text-transform: uppercase;
      line-height: 1.2;
      color: $theme-white !important;

      b {
        font-weight: $font-bold;
        display: block;
        @media screen and (min-width:768px){
          display: inline;
        }
      }

      @media screen and (min-width:768px){
        text-align: right;
      }

      @media screen and (min-width:992px){
        font-size: 32px;
      }
    }
  }

  &__btn {
    font-size: 15px;
    border-radius: 15px;
    background-color: $theme-white;
    display: inline-block;
    margin: auto;
    padding: 7px 40px;
    text-align: center;
    color: $theme-color-7;
    font-weight: 600;
    min-width: 250px;
    text-transform: uppercase;
    transition: all 200ms;

    &:hover {
      color: $theme-white;
      background-color: $theme-color-7; 
    }

    @media screen and (min-width:768px){
      display: none;
    }
  }
}