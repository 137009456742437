.list-blocks {
  &__item { 
    margin-top: 80px; 
    @include media-breakpoint-down(xs) { margin-top: 0; }
    + .embed-responsive {
      margin-bottom: 80px !important; 
    }
  }
  &__thumb {
    width: 100%;
    height: 445px;
    @include media-breakpoint-down(lg) { height: 370px; }
    @include media-breakpoint-down(md) { height: 270px; }
    @include media-breakpoint-down(sm) { height: 195px; }
    @include media-breakpoint-down(xs) { 
      margin-bottom: 30px; 
      height: 315px;
    }
  }
  &__body {
    padding-right: 40px;
    @include media-breakpoint-down(sm) { padding-right: 0; } 
  }
  &__title {
    font-weight: 700;
    font-size: 14px;
  }
  h3 {
    font-size: 35px;
    margin-top: 0;
    margin-bottom: 20px;
    font-family: $theme-font-2;
    color: $theme-color-11;
    font-weight: 600;
    strong { font-weight: 500; }
    /*@include media-breakpoint-down(lg) { font-size: 53px; }
    @include media-breakpoint-down(md) { 
      font-size: 45px; 
      margin-top: 25px;
    }
    @include media-breakpoint-down(sm) { 
      margin: 15px 0;
      font-size: 36px;
    }
    @include media-breakpoint-down(xs) { 
      font-size: 32px; 
      line-height: 33px;
      margin: 15px 0 10px;
    }*/
  }

  h5{
    font-size: 22px;
    font-family: $theme-font-2;
    color: $theme-color-11; 
    font-weight: 500;
  }

  h6 {
    font-family: $theme-font-2;
    color: $theme-color-11; 
  }
  p, ol, ul {
    font-size: 16px;
    line-height: 1.5em;
    font-family: $theme-font-2;
  }

  ul {
    list-style: none; /* Remove default bullets */
    li::before {
      content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
      color: $theme-color-11;
      font-weight: bold;
      display: inline-block;
      width: 1em;
      margin-left: -1em;
    }

    ul > li::before {
      content:"\25CB";
    }
  }

  &__link {
    margin-top: 30px;
    display: inline-block;
    background: $theme-color-2;
    color: $theme-white;
    padding: 10px 75px;
    font-size: 12px;
    margin-bottom: 65px;
    &.hvr-sweep-to-right:before {
      background: $theme-color-1;
    }
    @include media-breakpoint-down(md) { margin-bottom: 35px; }
    @include media-breakpoint-down(sm) { margin: 25px 0; }
    @include media-breakpoint-down(xs) { 
      width: 100%; 
      text-align: center;
    }
  }
}