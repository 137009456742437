.box-one {
  @extend .box-company;
  @include media-breakpoint-down(xs) { padding: 30px 0; }
  @include media-breakpoint-down(sm) { 
    min-height: auto!important;
    .container { height: auto!important; } 
  }
  &__image { 
    @extend .box-company__image; 
    @include media-breakpoint-down(xs) { background-position: center center; }
  }
  &__body { 
    @extend .box-company__body; 
    padding-top: 140px!important;
    @include media-breakpoint-down(md) { padding-top: 0!important; }
  }
  &__title { @extend .box-company__title; }
  h3 {
    font-size: 50px;
    margin-top: 45px;
    margin-bottom: 0;
    font-family: $theme-font-2;
    font-weight: 300;
    width: 67%;
    line-height: 50px;
    strong { font-weight: 500; }
    @include media-breakpoint-down(lg) { font-size: 53px; }
    @include media-breakpoint-down(md) { 
      font-size: 43px;
      line-height: 43px;
    }
    @include media-breakpoint-down(sm) { width: 100%; }
    @include media-breakpoint-down(xs) { 
      font-size: 32px; 
      line-height: 35px;
      margin-top: 10px;
    }
  }
  span {
    display: block;
    font-size: 26px;
    font-weight: 300;
    margin-bottom: 80px;
    @include media-breakpoint-down(md) { font-size: 24px; }
    @include media-breakpoint-down(sm) { 
      margin-bottom: 380px!important; 
      width: 45%;
    }
    @include media-breakpoint-down(xs) { 
      margin-bottom: 20px!important; 
      font-size: 16px; 
      width: 100%;
    }
  }
  p {
    font-size: 14px;
    line-height: 21px;
  }
}
//
.box-two {
  @extend .box-catalogue;
  @include media-breakpoint-down(xs) { padding-bottom: 30px; }
  &__thumb { @extend .box-catalogue__thumb; }
  &__body { 
    @extend .box-catalogue__body; 
    padding-left: 20px!important;
    margin-bottom: 70px;
    @include media-breakpoint-down(md) { margin-bottom: 40px; }
    @include media-breakpoint-down(xs) { padding-left: 0!important; }
  }
  &__title { @extend .box-catalogue__title; }
  &__header { @extend .box-catalogue__header; }
  h3 {
    font-size: 50px;
    margin-top: 45px;
    margin-bottom: 0;
    font-family: $theme-font-2;
    font-weight: 300;
    strong { font-weight: 500; }
    @include media-breakpoint-down(lg) { font-size: 40px; }
    @include media-breakpoint-down(md) { 
      font-size: 45px; 
      margin-top: 25px;
    }
    @include media-breakpoint-down(sm) { 
      margin: 15px 0;
      font-size: 36px;
    }
    @include media-breakpoint-down(xs) { 
      font-size: 32px; 
      line-height: 38px;
      margin: 35px 0 0;
    }
  }
  span {
    display: block;
    font-size: 26px;
    font-weight: 300;
    margin-bottom: 75px;
    @include media-breakpoint-down(md) { 
      font-size: 24px; 
      margin-bottom: 40px;
    }
    @include media-breakpoint-down(sm) { 
      margin-bottom: 0; 
      font-size: 22px;
    }
    @include media-breakpoint-down(xs) { 
      font-size: 16px;
      margin-bottom: 20px; 
    }
  }
  p {
    font-size: 14px;
    line-height: 21px;
    width: 60%;
    @include media-breakpoint-down(md) { width: 100%; }
  }
}
//
.box-four {
  padding: 70px 0;
  background: $theme-color-3;
  @include media-breakpoint-down(xs) { padding: 35px 0; }
  &__title { @extend .box-world__title; }
  h3 {
    font-size: 50px;
    margin-top: 35px;
    margin-bottom: 25px;
    font-family: $theme-font-2;
    font-weight: 300;
    strong { font-weight: 500; }
    @include media-breakpoint-down(xs) { 
      font-size: 35px; 
    }
  }
  span {
    display: block;
    font-size: 26px;
    font-weight: 300;
    margin-bottom: 75px;
    @include media-breakpoint-down(sm) { font-size: 30px; }
    @include media-breakpoint-down(xs) { 
      font-size: 16px; 
      margin-bottom: 25px;
    }
  }
  p {
    font-size: 14px;
    line-height: 21px;
    width: 45%;
    margin: 0 auto;
    @include media-breakpoint-down(md) { width: 75%; }
    @include media-breakpoint-down(sm) { width: 100%; }
  }
}