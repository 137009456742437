.list-kit-prod {
  &__item {
    margin-bottom: 35px;
    &:hover {
      .list-kit-prod__image div { transform: scale(1.15); }
    }
  }
  &__image {
    width: 100%;
    height: 380px;
    overflow: hidden;
    position: relative;
    @include media-breakpoint-down(lg) { height: 315px; }
    @include media-breakpoint-down(sm) { height: 360px; }
    @include media-breakpoint-down(xs) { height: 195px; }
    &--relProd { height: 250px!important; }
    div {
      width: 100%;
      height: 100%;
      transition: transform 0.3s;
    }
  }
  &__title {
    font-size: 18px;
    text-align: center;
    font-family: $theme-font-2;
    font-weight: 300;
    &--relProd { font-size: 16px; }
  }
}