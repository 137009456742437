.company {
  padding-top: 60px;
  @include media-breakpoint-down(xs) { padding-top: 25px; }
  &__aside {
    @include media-breakpoint-down(xs) { display: none; }
    ul { 
      margin-top: 210px; 
      padding-left: 0;
      padding-right: 50px;
      list-style-type: none;
      @include media-breakpoint-down(md) { 
        margin-top: 50px; 
        padding-right: 0;
      }
    }
    a {
      font-size: 15px;
      line-height: 40px;
      display: block;
      i {
        opacity: 0;
        transition: opacity .3s ease-out;
        float: right;
        line-height: 40px;
        font-size: 16px;
      }
      &:hover {
        font-size: 25px;
        font-weight: 700;
        i {
          opacity: 1;
          transition: opacity .3s ease-in;
        }
        @include media-breakpoint-down(sm) { font-size: 18px; } 
      }
    }
  }
  &__body {
    position: relative;
    width: 100%;
    height: 700px;
    @include media-breakpoint-down(lg) { height: 570px; }
    @include media-breakpoint-down(md) { height: 390px; }
    @include media-breakpoint-down(xs) { display: none; }
  }
  &__image {
    width: 100%;
    height: 100%;
    padding-right: 100px;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: 1;
    transition: opacity .3s ease-in-out;
    margin-bottom: 0;
    &.active {
      opacity: 1; 
      z-index: 3;
    }
    @include media-breakpoint-down(lg) { height: 570px; }
    @include media-breakpoint-down(md) { height: 390px; }
    @include media-breakpoint-down(sm) { 
      height: 370px; 
      padding-right: 50px;
    }
    /*
    @include media-breakpoint-down(xs) { height: 210px; }*/
    div {
      width: 100%;
      height: 100%;
      position: relative;
      background-color: $theme-color-2;
      overflow: hidden;
      /*@include media-breakpoint-down(xs) { overflow: inherit; }*/
    }
  }
  &__title {
    color: $theme-white;
    font-size: 45px;
    font-family: $theme-font-2;
    font-weight: 500;
    position: absolute;
    right: -90px;
    bottom: 90px;
    z-index: 2;
    &--dark {
      color: $theme-color-1;
      right: 10px;
      bottom: 91px;
      z-index: 1;
      line-height: 1.5;
    }
    @include media-breakpoint-down(lg) {
      font-size: 40px;
      &--dark { bottom: 92px; }
    } 
    @include media-breakpoint-down(lg) {
      font-size: 30px;
      &--dark { bottom: 94px; }
    } 
    @include media-breakpoint-down(sm) { 
      font-size: 20px;
      right: -70px;
      &--dark { 
        bottom: 95px;
        right: -20px;
      }
    }
  }
  &__slider {
    display: none;
    @include media-breakpoint-down(xs) { display: block; }
    &__title {
      font-weight: 700;
      font-size: 20px;
      display: block;
      text-align: center;
      margin-bottom: 20px;
    }
    &__image {
      width: 100%;
      height: 415px;
    }
    .slick-prev, .slick-next {
      top: 13px;
      z-index: 9999;
      &:before { 
        color: $theme-color-1; 
        font-family: 'Glyphicons Halflings';
        font-size: 25px;
      }
    }
    .slick-prev { 
      left: 20px; 
      &:before { content: "\e251"; }
    }
    .slick-next { 
      right: 20px; 
      &:before { content: "\e250"; }
    }
  }
}