.product {
  padding: 50px 0 150px;
  .secondImg{
    margin-bottom: 30px;
  }
  .colImage{
    min-height: 300px !important;
  }
  @include media-breakpoint-down(xs) { padding: 25px 0; }
  
  &__cat-name {
    display: block;
    font-size: 14px;
  }
  &__title {
    margin: 23px 0 23px;
    font-size: 50px;
    font-family: $theme-font-2;
    font-weight: 300;
    svg { fill: $theme-color-2; }
    @include media-breakpoint-down(sm) {
      font-size: 45px;
      margin-bottom: 25px;
      svg { display: none; }
    }
    @include media-breakpoint-down(xs) { font-size: 35px; }
  }
  &__code {
    display: block;
    font-size: 26px;
    font-weight: 300;
    margin-bottom: 80px;
    @include media-breakpoint-down(xs) { margin-bottom: 35px; }
  }
  &__text { 
    // font-size: 14px; 
    h3 {
      font-weight: 700;
      font-size: 28px;
      margin-bottom: 25px;
    }
    table {
      width: 100%;
      tr {
        td:first-child { 
          width: 30%; 
          @include media-breakpoint-down(md) { width: 40% }
          @include media-breakpoint-down(xs) { width: 40% }
        }
        td:last-child { 
          width: 70%; 
          @include media-breakpoint-down(md) { width: 60% }
          @include media-breakpoint-down(xs) { width: 60% }
        }
      }
      td { border-top: none; }
      h4 { 
        font-size: 20px; 
        font-weight: 700;
      }
      img {
        vertical-align: top;
        height: 135px;
        width: 100%;
        @include media-breakpoint-down(md) { height: 90px; } 
        @include media-breakpoint-down(xs) { height: 65px; } 
      }
    }
  }
  &__image {
    width: 100%;
    height: 600px;
    margin-top: 150px;
    margin-bottom: 40px;
    &--visual { margin-top: -340px; }
    @include media-breakpoint-down(lg) { 
      height: 530px; 
      &--visual { margin-top: -270px; }
    }
    @include media-breakpoint-down(md) { 
      height: 450px; 
      margin-top: -180px;
      &--visual { margin-top: -250px; }
    }
    @include media-breakpoint-down(sm) { 
      height: 345px; 
      margin-top: -170px;
      float: right;
      width: 40%;
      &--visual { margin-top: -230px; }
    }
    @include media-breakpoint-down(xs) { 
      width: 100%;
      height: 600px;
      margin-top: 0;
      float: none;
      &--visual { margin-top: 0; }
    }
  }
  &__render {
    width: 100%;
    height: 925px;
    margin-top: 60px;
    position: relative;
    &--oriz { height: 390px; }
    &:hover {
      .product__render__overlay { 
        opacity: 1;
        transition: all .4s ease-in;
      }
    }
    &__overlay {
      opacity: 0;
      transition: all .4s ease-out;
      background: rgba($theme-black, .3);
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      svg {
        fill: $theme-white;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(50%);
      }
    }
    @include media-breakpoint-down(xs) { 
      height: 490px; 
      margin-top: 0;
    }
  }
  &__link {
    margin: 0 auto 15px;
    font-size: 15px;
    //border: 1px solid $theme-color-1;
    border-radius: 15px;
    background:$theme-color-9;
    padding: 7px 40px;
    display: inline-block;
    color: $theme-white;
    overflow: hidden;
    i { margin-left: 10px; }
    &.hvr-sweep-to-right:before {
      background: $theme-color-10;
      color: $theme-white;
    }
    &--info { padding: 7px 45px; }
    &--texture { 
      margin-top: 200px; 
      @include media-breakpoint-down(sm) { margin-top: 50px; }
    }
  }
  &__variants {
    background: $theme-color-4;
    padding: 60px 0;
    &__label {
      display: block;
      margin-bottom: 40px;
      font-size: 14px;
    }
    &__title {
      font-size: 50px;
      font-family: $theme-font-2;
      font-weight: 300;
      margin-bottom: 80px;
      strong { font-weight: 500; }
      @include media-breakpoint-down(sm) { 
        font-size: 45px;
        margin-bottom: 40px;
      }
    }
    &__content {
      padding-bottom: 100px;
      @include media-breakpoint-down(sm) { padding-bottom: 50px; }
      @include media-breakpoint-down(xs) { padding-bottom: 30px; }
    }
    &__item {  
      margin-bottom: 40px;
      @include media-breakpoint-down(sm) { margin-bottom: 20px; }
    }
    &__image {
      width: 100%;
      height: 475px;
      padding-right: 40px;
      margin-bottom: 0;
      @include media-breakpoint-down(lg) { height: 385px; }
      @include media-breakpoint-down(md) { height: 260px; }
      @include media-breakpoint-down(sm) { 
        height: 370px; 
        padding-right: 0;
      }
      @include media-breakpoint-down(xs) { height: 210px; }
      div {
        width: 100%;
        height: 100%;
        position: relative;
        background-color: rgba($theme-color-2, .2);
        overflow: hidden;
        @include media-breakpoint-down(sm) { overflow: inherit; }
      }
    }
    &__name {
      color: $theme-white;
      font-size: 35px;
      font-family: $theme-font-2;
      font-weight: 500;
      position: absolute;
      right: -40px;
      top: 395px;
      transition: all .4s ease-in;
      z-index: 2;
      &--dark {
        color: $theme-color-1;
        right: 15px;
        z-index: 1;
        line-height: 1.2;
      }
      @include media-breakpoint-down(lg) { top: 305px; }
      @include media-breakpoint-down(md) { 
        top: 200px; 
        font-size: 28px;
      }
      @include media-breakpoint-down(sm) { 
        font-size: 18px;
        color: $theme-color-1;
        position: relative;
        top: inherit;
        right: inherit;
        margin-top: 15px;
        &--dark { display: none;  }
      }
    }
    &__link-collapse {
      font-size: 14px;
      margin-bottom: 30px;
      display: inline-block;
      i { margin-left: 15px; }
      &.collapsed i {
        transform: rotate(180deg);
      }
    }
    &__collapse {
      opacity: 0;
      transition: opacity .2s ease-out;
      &.show {
        opacity: 1;
        transition: opacity .2s ease-in;
      }
    }
  }
  &__list-variants {
    margin-bottom: 50px;
    &__title {
      font-size: 22px;
      font-weight: 600;
      margin-bottom: 25px;
      margin-top: 0;
    }
    &__image {
      height: 195px;
      margin-bottom: 30px;
      background-color: rgba($theme-color-2, .2);
      @include media-breakpoint-down(md) { height: 245px; }
      @include media-breakpoint-down(sm) { height: 175px; }
      @include media-breakpoint-down(xs) { height: 270px; }
      &--tab {
        height: 235px;
        @include media-breakpoint-down(lg) { height: 195px; }
        @include media-breakpoint-down(md) { height: 120px; }
        @include media-breakpoint-down(sm) { height: 160px; }
        @include media-breakpoint-down(xs) { height: 270px; }
      }
    }
    p, ul { font-size: 14px; }
  }
  &__gallery {
    margin-bottom: 50px;
    &--cat {
      margin-bottom: 20px;
      margin-top: 50px;
    }
    &__title {
      margin-top: 0;
      margin-bottom: 10px;
      font-size: 20px;
      font-weight: 600;
      &--cat {
        margin-bottom: 30px;
        font-family: $theme-font-2;
        padding-top: 25px;
        font-size: 30px;
        font-weight: 400;
      }
    }
    &__item {
      padding: 0 15px;
      margin-bottom: 10px;
      &__title {
        font-size: 16px;
        font-weight: 300;
      }
    }
    &__image {
      width: 100%;
      height: 270px;
      position: relative;
      &__text{
        opacity: 1;
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        color: #174E3B;
        transition: all .4s ease-in;
        p{
          font-size: 16px;
          font-weight: 400;
          position: absolute;
          top: 50%;
          left: 0;
          width: 80%;
          transform: translate(0, -50%);
          background: rgba(255, 255, 255, 0.8);
          padding: 20px;
          transition: all .4s ease-in;
          font-family: "Montserrat", sans-serif;
        }
      }
      &--cat {
        height: 350px;
        @include media-breakpoint-down(lg) { height: 290px; }
        @include media-breakpoint-down(md) { height: 210px; }
        @include media-breakpoint-down(sm) { height: 150px; }
        @include media-breakpoint-down(xs) { height: 315px; }
      }
      &:hover {
        .product__gallery__image__overlay{ 
          opacity: 1;
          transition: all .4s ease-in;
        }
        // .product__gallery__image__text{
        //   left: -10%;

          
        // }
      }
      &__overlay {
        opacity: 0;
        transition: all .4s ease-out;
        background: rgba($theme-black, .3);
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        svg {
          fill: $theme-white;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translateX(-50%) translateY(-50%);
        }
      }
    }
    .slick-prev, .slick-next {
      width: 13px;
      height: 21px;
      background-image: url('/images/svg/arrow.svg');
      background-repeat: no-repeat;
      &:before { display: none; }
    }
    .slick-next {
      transform: rotate(-180deg);
    }
  }
  &__relProdsTitle {
    font-family: $theme-font-2;
    margin-bottom: 35px;
    text-align: center;
    font-weight: 300;
    @include media-breakpoint-down(xs) { margin: 30px 0; }
  }

  &--elementum {
    .product__cat-name {
      font-size: 25px;
      font-weight: $font-normal;
      color: $theme-color-11;
    }
    .product__code{
      margin-bottom: 50px;
    }
    .product__title {
      display: flex;
      align-items: center;
      font-size:40px;
      font-weight: $font-extrabold;
      color: $theme-color-11;
      svg { 
        margin-right: 10px;
        width: 40px;
        height: 40px;
        fill: $theme-color-11;
        @include media-breakpoint-down(md) { margin-right: 0; }
      }
    }
    .product__text {
      font-size: 16px;
      font-family: $theme-font-2;
    }
    .product__render,
    .product__image{
      margin-top: 50px;
    }
  }
}

.product__text {
  font-size: 16px;
  font-family: $theme-font-2;
}


.t_catalog_category_with_images .product__image {
  @include media-breakpoint-down(md) { margin-top: 0; }
}
.t_catalog_product_table_with_gallery .product__link--info {
  margin-top: 60px;
  @include media-breakpoint-down(sm) { margin-top: 0; }
}